/* eslint-disable no-underscore-dangle */
import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  HttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { ConfigService } from '@shuttlerock/configuration';
import { getAccessTokenSilently } from '@creative-foundation/auth';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {
  InvalidationPolicyCache,
  RenewalPolicy,
} from '@nerdwallet/apollo-cache-policies';
import { getMainDefinition } from '@apollo/client/utilities';
import { paginationFieldPolicy, unshiftFieldPolicy } from './fieldPolicies';

const cache = new InvalidationPolicyCache({
  typePolicies: {
    Query: {
      fields: {
        clients: paginationFieldPolicy(['id', 'q', 'sort']),
        copySuggestions: unshiftFieldPolicy([
          'audience',
          'audienceIntegrationKey',
          'description',
          'conceptIdea',
        ]),
        creators: paginationFieldPolicy(['$filter']),
        deliverables: paginationFieldPolicy(['orderIntegrationKey', 'q']),
        orders: paginationFieldPolicy(['q', 'sort']),
        users: paginationFieldPolicy(['clientIntegrationKey', 'q', 'sort']),
      },
    },
    UGCOrder: {
      fields: {
        deliverableLinks: {
          merge: false,
        },
      },
    },
  },
  dataIdFromObject(object) {
    if (
      object.__typename === 'CreatorAttributes' ||
      object.__typename === 'Scopes' ||
      object.__typename === 'GenerativeAIOptions'
    ) {
      // Scope queries can all be stored at the root level.
      return object.__typename;
    }
    if (object.__typename === 'Language' && object.code) {
      return `${object.__typename}:${object.code}`;
    }
    // TODO: once all graphs are federated compliant, we can remove this and always use `key` or `integrationKey`
    if (object.__typename === 'BrandProfile' && object.integrationKey) {
      return `${object.__typename}:${object.integrationKey}`;
    }
    if (object.__typename === 'Project' && object.integrationKey) {
      return `${object.__typename}:${object.integrationKey}`;
    }
    if (object.__typename === 'UGCDeliverable' && object.key) {
      return `${object.__typename}:${object.key}`;
    }
    if (object.__typename === 'Concept' && object.integrationKey) {
      return `${object.__typename}:${object.integrationKey}`;
    }
    if (object.__typename === 'ConceptBrief' && object.key) {
      return `${object.__typename}:${object.key}`;
    }
    if (object.__typename === 'ClientAccount' && object.key) {
      return `${object.__typename}:${object.key}`;
    }
    if (object.__typename === 'ClientBrand' && object.key) {
      return `${object.__typename}:${object.key}`;
    }
    if (object.__typename === 'GenerativeAIModel' && object.code) {
      return `${object.__typename}:${object.code}`;
    }
    if (object.__typename === 'DeliveryTurnaround' && object.key) {
      return `${object.__typename}:${object.key}`;
    }

    // Fallback to default id handling
    return defaultDataIdFromObject(object);
  },
  invalidationPolicies: {
    timeToLive: 30 * 60 * 1000, // 30 min TTL on all types in the cache
    renewalPolicy: RenewalPolicy.WriteOnly,
  },
});

const addHeaders = setContext(
  async (_, { headers }: { headers?: Record<string, unknown> }) => {
    const locale = localStorage.getItem('i18nextLng');
    const e2eTesting = localStorage.getItem('e2e-testing');
    const { accessToken } = await getAccessTokenSilently();

    return {
      headers: {
        ...headers,
        ...(accessToken && { authorization: `Bearer ${accessToken}` }),
        ...(locale && { 'Accept-Language': locale }),
        ...(e2eTesting && { 'X-E2E-Testing': e2eTesting }),
      },
    };
  },
);

const link = ApolloLink.from([
  addHeaders,
  new MultiAPILink({
    endpoints: {
      federated: `${ConfigService.SUPERGRAPH_BASE_URL()}`,
      concept: `${ConfigService.CONCEPT_BASE_URL()}`,
      copilot: `${ConfigService.COPILOT_BASE_URL()}`,
      customer: `${ConfigService.CUSTOMER_BASE_URL()}`,
      order: `${ConfigService.ORDER_BASE_URL()}`,
      production: `${ConfigService.PRODUCTION_BASE_URL()}`,
      project: `${ConfigService.PROJECT_BASE_URL()}`,
      policy: `${ConfigService.POLICY_BASE_URL()}`,
      render: `${ConfigService.RENDER_API_BASE_URL()}`,
      revision: `${ConfigService.REVISION_BASE_URL()}`,
      specification: `${ConfigService.SPECIFICATION_BASE_URL()}`,
      storage: `${ConfigService.STORAGE_BASE_URL()}`,
      usage: `${ConfigService.USAGE_BASE_URL()}`,
      creator: `${ConfigService.CREATOR_BASE_URL()}`,
    },
    defaultEndpoint: 'order',
    createHttpLink: () =>
      createUploadLink({
        credentials: 'include',
      }),
  }),
]);

const federatedLink = ApolloLink.from([
  addHeaders,
  new HttpLink({
    uri: `${ConfigService.SUPERGRAPH_BASE_URL()}/graphql`,
  }),
]);

// The MultiAPILink is incompatible with subscriptions using multipart HTTP instead of websockets.
// We work around this by sending subscriptions directly to a supergraph HttpLink.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  federatedLink,
  link,
);

export const GraphQLClient = new ApolloClient({
  link: splitLink,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
  name: 'src-web',
  version: ConfigService.APP_VERSION(),
});
