export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: any;
  /** Credentials for uploading files to AWS */
  Credential: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated input type of AcceptInvitation */
export type AcceptInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** UUID of the token to be accepted. */
  invitation: InvitationAcceptInput;
};

/** Autogenerated return type of AcceptInvitation. */
export type AcceptInvitationPayload = {
  __typename?: 'AcceptInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invitation?: Maybe<Invitation>;
};

/** An account license. */
export type AccountLicense = {
  __typename?: 'AccountLicense';
  id: Scalars['ID'];
  maxActiveHeroes: Scalars['Int'];
  /** The maximum number of deliverables that can be actively in production at one time, for all clients in this license */
  maxDeliverablesInProduction: Scalars['Int'];
  /** The total number of deliverables currently in production for all clients in this license */
  totalDeliverablesInProduction: Scalars['Int'];
};

/** An account license plus. */
export type AccountLicensePlus = {
  __typename?: 'AccountLicensePlus';
  balanceExpiresAt?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
};

export enum AccountType {
  License = 'LICENSE',
  LicensePlus = 'LICENSE_PLUS',
  Subscription = 'SUBSCRIPTION',
  Unrestricted = 'UNRESTRICTED'
}

/** Autogenerated input type of AddDeliverableFormatToConcept */
export type AddDeliverableFormatToConceptInput = {
  /**
   * The ID of the advert
   * @deprecated Use advertIntegrationKey instead
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the advert */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deliverable formats to be used for the Advert. */
  deliverableFormatAttributes: AdvertsDeliverableFormatInput;
};

/** Autogenerated return type of AddDeliverableFormatToConcept. */
export type AddDeliverableFormatToConceptPayload = {
  __typename?: 'AddDeliverableFormatToConceptPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddSpecificationToDeliverable */
export type AddSpecificationToDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the deliverable to be updated. */
  integrationKey: Scalars['ID'];
  /** Integration key of the specification being associated to the deliverable. */
  specificationIntegrationKey: Scalars['ID'];
};

/** Autogenerated return type of AddSpecificationToDeliverable. */
export type AddSpecificationToDeliverablePayload = {
  __typename?: 'AddSpecificationToDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated deliverable, or null. */
  deliverable?: Maybe<Deliverable>;
};

/** An advert. */
export type Advert = {
  __typename?: 'Advert';
  /** AdvertsDeliverableFormats belonging to the Advert. */
  advertsDeliverableFormats: AdvertsDeliverableFormats;
  /** Answers submitted to the Advert. */
  answers: Array<Answer>;
  /**
   * Assets attached to the Advert
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  /** The UUID of the attached audience. */
  audienceIntegrationKey?: Maybe<Scalars['ID']>;
  /** The URL of the audio asset for the Advert. */
  audioUrl?: Maybe<Scalars['String']>;
  /**
   * Concept Variations belonging to the Advert.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  conceptVariations: Adverts;
  /** Indication of whether the deliverable contains AI. */
  containsAi: Scalars['Boolean'];
  /** The copy answers of the concept. */
  copyAnswers?: Maybe<Array<Answer>>;
  /**
   * Copy Variations belonging to the Advert.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  copyVariations: Adverts;
  /**
   * Deliverables belonging to the Advert.
   *
   * Permitted search attributes: ["id", "duration_filter", "order_client_name",
   * "order_client_vertical_name", "order_hubspot_id", "order_name",
   * "order_region_name", "order_serial_id", "copy_variation_number",
   * "deliverable_format_id", "deliverable_format_name",
   * "deliverable_format_platform_name", "hero", "language_code", "order_best_of",
   * "order_id", "pending_review_or_approved", "hero_or_copy_variation_filter",
   * "advert_position", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "order_best_of", "order_created_at", "order_completed_at"]
   */
  deliverables: LegacyDeliverables;
  /** Designer role Users assigned to the Advert. */
  designers: Array<LegacyUser>;
  /** The UUID of the Advert. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify an Advert when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Language variations requested for the Advert. */
  languageVariationCodes?: Maybe<Array<Scalars['String']>>;
  /**
   * The URL of the meta audio asset for the Advert.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: Maybe<Scalars['String']>;
  /** The user-specified name for the Advert. */
  name?: Maybe<Scalars['String']>;
  /** The next advert. */
  nextAdvert?: Maybe<Advert>;
  /** The total number of concept variations. */
  numConceptVariations: Scalars['Int'];
  /** The total number of copy variations. */
  numCopyVariations: Scalars['Int'];
  /** The total number of languages. */
  numLanguages: Scalars['Int'];
  /** The campaign objective of the advert. */
  objective?: Maybe<Objective>;
  /** The order assigned to the Advert. */
  order?: Maybe<Order>;
  /** The input type as determined by the file types of order assets. */
  originalInputType: OriginalInputType;
  /** AKA the concept number. */
  position: Scalars['Int'];
  /** The previous advert. */
  previousAdvert?: Maybe<Advert>;
  /** The production notes of the Advert. */
  productionNotes?: Maybe<Scalars['String']>;
  /**
   * Questions associated with the advert.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  questions: Array<Question>;
  /** The root advert this advert belongs to if it is a variation. */
  rootAdvert?: Maybe<Advert>;
  /** The submission state of the Advert. */
  submissionState: SubmissionState;
  /** The total number of deliverables ordered for the Advert. */
  totalDeliverables?: Maybe<Scalars['Int']>;
  /** Flag determining if a child advert should use the answers given to their parent. */
  useRootAdvertAnswers: Scalars['Boolean'];
};


/** An advert. */
export type AdvertAdvertsDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


/** An advert. */
export type AdvertAnswersArgs = {
  actual?: InputMaybe<Scalars['Boolean']>;
};


/** An advert. */
export type AdvertAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertConceptVariationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertCopyVariationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertDeliverablesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An advert. */
export type AdvertQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** A container for arguments used when creating an advert */
export type AdvertCreateInput = {
  /** Deliverable formats to be used for the Advert. */
  advertsDeliverableFormatsAttributes?: InputMaybe<Array<AdvertsDeliverableFormatInput>>;
  /** Language variations to be assigned to the Advert. */
  languageVariationCodes?: InputMaybe<Array<Scalars['String']>>;
  /** The name of the advert. */
  name?: InputMaybe<Scalars['String']>;
  /** The total number of concept variations. */
  numConceptVariations?: InputMaybe<Scalars['Int']>;
  /** The total number of copy variations. */
  numCopyVariations?: InputMaybe<Scalars['Int']>;
  /**
   * The UUID of the order to be attached by this advert.
   * @deprecated Use `orderIntegrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the order to be attached by this advert. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when updating an advert */
export type AdvertUpdateInput = {
  /** Deliverable formats to be used for the Advert. */
  advertsDeliverableFormatsAttributes?: InputMaybe<Array<AdvertsDeliverableFormatInput>>;
  /** Asset UUIDs to be associated with the advert. */
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The UUID of the attached audience. */
  audienceIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Designers to be assigned to the advert. */
  designerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** UUID of the target advert. */
  id?: InputMaybe<Scalars['ID']>;
  /** IntegrationKey of the target advert. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** Language variations to be assigned to the Advert. */
  languageVariationCodes?: InputMaybe<Array<Scalars['String']>>;
  /** The name of the advert. */
  name?: InputMaybe<Scalars['String']>;
  /** The total number of concept variations. */
  numConceptVariations?: InputMaybe<Scalars['Int']>;
  /** The total number of copy variations. */
  numCopyVariations?: InputMaybe<Scalars['Int']>;
  /** The campaign objective of the advert. */
  objective?: InputMaybe<Objective>;
  /** The production notes of the Advert. */
  productionNotes?: InputMaybe<EncodedString>;
  /** Flag determining if a child advert should use the answers given to their parent. */
  useRootAdvertAnswers?: InputMaybe<Scalars['Boolean']>;
};

/** A paginated array of Adverts */
export type Adverts = {
  __typename?: 'Adverts';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Adverts */
  nodes: Array<Advert>;
};

/** An adverts deliverable format type. */
export type AdvertsDeliverableFormat = {
  __typename?: 'AdvertsDeliverableFormat';
  /** The deliverable format assigned to the Advert. */
  deliverableFormat: DeliverableFormat;
  /** The dimension of deliverable. */
  dimension?: Maybe<Dimension>;
  /** The length of deliverable. */
  formatLength: FormatLength;
  /** Flags the AdvertDeliverableFormat as the hero candidate. */
  hero: Scalars['Boolean'];
  /** The UUID of the AdvertsDeliverableFormat. */
  id: Scalars['ID'];
};

/** A container for arguments used when creating an adverts deliverable format */
export type AdvertsDeliverableFormatInput = {
  /** The UUID of the deliverable format to be attached by this advert. */
  deliverableFormatId: Scalars['ID'];
  /** The dimensions of requested deliverables. */
  dimensions: Array<DeliverableFormatDimensionInput>;
};

/** A paginated array of AdvertsDeliverableFormats */
export type AdvertsDeliverableFormats = {
  __typename?: 'AdvertsDeliverableFormats';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of AdvertsDeliverableFormats */
  nodes: Array<AdvertsDeliverableFormat>;
};

/** The answer to a question asked during submission of an order */
export type Answer = {
  __typename?: 'Answer';
  /** The answer in English */
  englishResponseText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  question: Question;
  /** @deprecated Use `question.id` instead. */
  questionId: Scalars['ID'];
  questionLabel?: Maybe<Scalars['String']>;
  responseText?: Maybe<Scalars['String']>;
};

/** A container for arguments used when answering a Question */
export type AnswerCreateInput = {
  questionId: Scalars['ID'];
  responseText?: InputMaybe<EncodedString>;
};

/** Autogenerated input type of AnswerQuestions */
export type AnswerQuestionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  resource: BulkAnswersInput;
};

/** Autogenerated return type of AnswerQuestions. */
export type AnswerQuestionsPayload = {
  __typename?: 'AnswerQuestionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  order: Order;
};

/** A paginated array of Answers */
export type Answers = {
  __typename?: 'Answers';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Answers */
  nodes: Array<Answer>;
};

/** Autogenerated input type of ApproveDeliverables */
export type ApproveDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order to be approved. */
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveDeliverables. */
export type ApproveDeliverablesPayload = {
  __typename?: 'ApproveDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The approved Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of ArchiveAsset */
export type ArchiveAssetInput = {
  asset: AssetArchiveInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ArchiveAsset. */
export type ArchiveAssetPayload = {
  __typename?: 'ArchiveAssetPayload';
  asset?: Maybe<Asset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ArchiveAssets */
export type ArchiveAssetsInput = {
  /** Integration keys of the assets to be archived. */
  assetIntegrationKeys: Array<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The integration key of the branded order to be detached from assets. */
  orderIntegrationKey: Scalars['ID'];
};

/** Autogenerated return type of ArchiveAssets. */
export type ArchiveAssetsPayload = {
  __typename?: 'ArchiveAssetsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A file uploaded by the client to be used by designers */
export type Asset = {
  __typename?: 'Asset';
  assetType: AssetType;
  /**
   * Branded Orders attached to the Asset.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  brandedOrders: Orders;
  client?: Maybe<ClientBrand>;
  clientIntegrationKey: Scalars['ID'];
  format: Scalars['String'];
  id: Scalars['ID'];
  integrationKey: Scalars['ID'];
  name: Scalars['String'];
  notes: Array<AssetNote>;
  presignedUrl: Scalars['String'];
  size: Scalars['BigInt'];
  storageKey: Scalars['String'];
  /** The url to the thumbnail for this Asset. This will be null for unsupported asset types */
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


/** A file uploaded by the client to be used by designers */
export type AssetBrandedOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** A file uploaded by the client to be used by designers */
export type AssetThumbnailUrlArgs = {
  width?: InputMaybe<Scalars['Int']>;
};

/** A container for arguments used when archiving an asset */
export type AssetArchiveInput = {
  /** The UUID of asset. */
  id: Scalars['ID'];
  /**
   * The UUID of the branded order to be detached from asset.
   * @deprecated Use `order_integration_key` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the branded order to be detached from asset. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when creating an asset */
export type AssetCreateInput = {
  /** The asset type of the asset */
  assetType: AssetType;
  /** Integration key of the client brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /**
   * The ID of the client the asset belongs to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** The file format of the asset */
  format: Scalars['String'];
  /** The file name of the asset */
  name: Scalars['String'];
  /**
   * UUID of an order to attach the new asset to.
   * @deprecated Use `orderIntegrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** Integration key of an order to attach the new asset to. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** The file size of the asset */
  size: Scalars['BigInt'];
  /** The AWS url where the asset is stored */
  url: Scalars['String'];
};

/** A note attached to an Asset */
export type AssetNote = {
  __typename?: 'AssetNote';
  content: Scalars['String'];
  createdAt: Scalars['BigInt'];
  id: Scalars['ID'];
  updatedAt: Scalars['BigInt'];
};

export enum AssetType {
  AssetZip = 'asset_zip',
  Audio = 'audio',
  Brand = 'brand',
  BrandLogo = 'brand_logo',
  CombinedZip = 'combined_zip',
  DeliverablesZip = 'deliverables_zip',
  Font = 'font',
  Logo = 'logo',
  Music = 'music',
  Reference = 'reference',
  Stills = 'stills',
  TextField = 'text_field',
  Video = 'video',
  WorkingFilesZip = 'working_files_zip'
}

/** A paginated array of Assets */
export type Assets = {
  __typename?: 'Assets';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Assets */
  nodes: Array<Asset>;
};

/** The async job */
export type AsyncJob = {
  __typename?: 'AsyncJob';
  data: Scalars['JSON'];
  id: Scalars['ID'];
};

/** An audience. */
export type Audience = {
  __typename?: 'Audience';
  /** The integration key of the audience. */
  key: Scalars['ID'];
};

/** A container for arguments used when updating the auth0_integration_key of a user. */
export type Auth0IntegrationKeyUpdateInput = {
  /** Auth0 User Id to be assigned to the user as an Integration Key. */
  auth0IntegrationKey: Scalars['ID'];
  /** UUID of the target user. */
  id: Scalars['ID'];
};

/** A container for arguments used when creating an asset */
export type BrandLogoCreateInput = {
  /** The integration key of the brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['String']>;
  /** The ID of the client the asset belongs to. */
  clientId?: InputMaybe<Scalars['ID']>;
  /** The file format of the asset */
  format: Scalars['String'];
  /** The file name of the asset */
  name: Scalars['String'];
  /** The file size of the asset */
  size: Scalars['BigInt'];
  /** The AWS url where the asset is stored */
  url: Scalars['String'];
};

/** Scopes for a given Brand. */
export type BrandScopes = {
  __typename?: 'BrandScopes';
  canCreateBrandProfile: Scalars['Boolean'];
  canShowBrandProfile: Scalars['Boolean'];
  canUpdateBrandProfile: Scalars['Boolean'];
};

/** A container for arguments used when reverting an order to UGC Ideation. */
export type BriefRevertToUgcIdeationInput = {
  /** Integration key of the concept. */
  conceptKey: Scalars['ID'];
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated input type of BriefSubmittedForAssetProduction */
export type BriefSubmittedForAssetProductionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the project to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of BriefSubmittedForAssetProduction. */
export type BriefSubmittedForAssetProductionPayload = {
  __typename?: 'BriefSubmittedForAssetProductionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForCsReview */
export type BriefSubmittedForCsReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: SubmittedBriefForCsReviewInput;
};

/** Autogenerated return type of BriefSubmittedForCsReview. */
export type BriefSubmittedForCsReviewPayload = {
  __typename?: 'BriefSubmittedForCsReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForProductionReview */
export type BriefSubmittedForProductionReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the project to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** The integration key of the user who submitted the order. */
  userIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of BriefSubmittedForProductionReview. */
export type BriefSubmittedForProductionReviewPayload = {
  __typename?: 'BriefSubmittedForProductionReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The optimized project. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of BriefSubmittedForUgcIdeation */
export type BriefSubmittedForUgcIdeationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: UgcIdeationSubmissionInput;
};

/** Autogenerated return type of BriefSubmittedForUgcIdeation. */
export type BriefSubmittedForUgcIdeationPayload = {
  __typename?: 'BriefSubmittedForUgcIdeationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** A container for arguments used when answering Questions in bulk. */
export type BulkAnswersInput = {
  /** @deprecated Use `advertIntegrationKey` instead. */
  advertId?: InputMaybe<Scalars['ID']>;
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  answers: Array<AnswerCreateInput>;
  /** @deprecated Use `orderIntegrationKey` instead. */
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when creating Assets in bulk. */
export type BulkAssetsInput = {
  /** Details of the assets to create. */
  assets: Array<AssetCreateInput>;
  /** Integration key of the client brand the asset belongs to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the client the assets belongs to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the order the assets belongs to.
   * @deprecated Use `integrationKey` instead.
   */
  orderId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order the assets belongs to. */
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of CleanupE2eTestData */
export type CleanupE2eTestDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CleanupE2eTestData. */
export type CleanupE2eTestDataPayload = {
  __typename?: 'CleanupE2eTestDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A base client. */
export type Client = {
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Client. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /** A surrogate UUID used to identify a client when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The sales region of the Client. */
  region: Region;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /**
   * Users belonging to the Client.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  users: Users;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


/** A base client. */
export type ClientAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};


/** A base client. */
export type ClientUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};

/** Scopes for a given client account. */
export type ClientAccountScopes = {
  __typename?: 'ClientAccountScopes';
  canInviteUser: Scalars['Boolean'];
};

export type ClientBrand = Client & PermittedAttributes & {
  __typename?: 'ClientBrand';
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** An array of asset sources that can be considered to supplement the provided assets. */
  assetSources?: Maybe<Array<Scalars['String']>>;
  /**
   * Assets belonging to the Client.
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   * @deprecated Retrieve brand assets via creative-customer-api instead.
   */
  assets: Assets;
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** The brand notes of the Client. */
  brandNotes?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the client brand. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /**
   * A surrogate UUID used to identify a client brand when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** A surrogate UUID used to identify a client brand when integrating with external apps. */
  key: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /**
   * Orders submitted for the Client.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  permittedAttributes: Scalars['JSON'];
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The production notes of the Client. */
  productionNotes?: Maybe<Scalars['String']>;
  /** The sales region of the Client. */
  region: Region;
  /** The submission state of the Client's assets. */
  submissionState: SubmissionState;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /**
   * Users belonging to the Client.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  users: Users;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


export type ClientBrandAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};


export type ClientBrandAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


export type ClientBrandOrdersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};


export type ClientBrandUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};

/** A container for arguments used when creating a client. */
export type ClientCreateInput = {
  /** The account type of the client. */
  accountType?: InputMaybe<AccountType>;
  /** The expiry date of the hero balance of a License Plus account type. */
  balanceExpiresAt?: InputMaybe<Scalars['ISO8601Date']>;
  /** A flag to determine if the client is brand. */
  brand?: InputMaybe<Scalars['Boolean']>;
  /** Brand guidelines URL relevant to the client. */
  brandGuidelinesUrl?: InputMaybe<Scalars['String']>;
  /** The brand notes of the client. */
  brandNotes?: InputMaybe<Scalars['String']>;
  /** An array of user UUIDs to be assigned as client partners for this client. */
  clientPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** An array of user UUIDs to be assigned as creative specialists for this client. */
  creativeSpecialistIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A brief description of the client and the service they offer. */
  description?: InputMaybe<Scalars['String']>;
  /** Facebook URL relevant to the client. */
  facebookUrl?: InputMaybe<Scalars['String']>;
  /** The feature flags of the client. */
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  /** The hero balance available for a License Plus account type. */
  heroBalance?: InputMaybe<Scalars['Int']>;
  /** The Hubspot ID used to link the record to Shuttlerock's sales database. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** A flag to determine if the client should inherit vertical, region, platforms and collaborators from its parent. */
  inheritParentFields?: InputMaybe<Scalars['Boolean']>;
  /** Instagram URL relevant to the client. */
  instagramUrl?: InputMaybe<Scalars['String']>;
  /** A flag to determine if deliverables for this client can be downloaded by logged out users. */
  loggedOutDownloading?: InputMaybe<Scalars['Boolean']>;
  /** The number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables?: InputMaybe<Scalars['Int']>;
  /** The number of active heroes allowed for a License account type. */
  maxActiveHeroes?: InputMaybe<Scalars['Int']>;
  /** The name of the client. */
  name: Scalars['String'];
  /** Other URL relevant to the client. */
  otherUrl?: InputMaybe<Scalars['String']>;
  /**
   * The UUID of the parent client.
   * @deprecated Use `parentKey` instead.
   */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The UUID IntegrationKey of the parent client. */
  parentKey?: InputMaybe<Scalars['ID']>;
  /** Pinterest URL relevant to the client. */
  pinterestUrl?: InputMaybe<Scalars['String']>;
  /** Platforms to be made available to the client. */
  platformIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A flag to determine if the client may download deliverables prior to approval. */
  preApprovalDownloading?: InputMaybe<Scalars['Boolean']>;
  /** An array of user UUIDs to be assigned as production manager for this client. */
  productionManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The name of the sales region that the client belongs to. */
  region?: InputMaybe<Scalars['String']>;
  /** Tiktok URL relevant to the client. */
  tiktokUrl?: InputMaybe<Scalars['String']>;
  /** Twitter URL relevant to the client. */
  twitterUrl?: InputMaybe<Scalars['String']>;
  /** The UUID of the industry vertical the client belongs to (i.e: travel, education). */
  verticalId?: InputMaybe<Scalars['ID']>;
  /** Website URL relevant to the client. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Youtube URL relevant to the client. */
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type ClientGroup = Client & PermittedAttributes & {
  __typename?: 'ClientGroup';
  /** The license details for Clients with the License account type. */
  accountLicense?: Maybe<AccountLicense>;
  /** The license plus details for Clients with the License Plus account type. */
  accountLicensePlus?: Maybe<AccountLicensePlus>;
  /** Whether or not this clients account is required to submit CES feedback. */
  accountRequiresCes: Scalars['Boolean'];
  /** The account type of the Client. */
  accountType: AccountType;
  /** The number of active deliverables for a subscription account type. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables for a license account type. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Ancestor of the current Client at a given depth. */
  ancestor?: Maybe<ClientGroup>;
  /** The ancestry depth of the Client */
  ancestryDepth: Scalars['Int'];
  /** Brand guidelines URL relevant to the Client. */
  brandGuidelinesUrl?: Maybe<Scalars['String']>;
  /** The name of the brand Client refers to. */
  brandName?: Maybe<Scalars['String']>;
  /** Flag used to determine if the Client can be discarded. */
  canDiscard: Scalars['Boolean'];
  /**
   * Clients who are set as children of the Client.
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  children: Clients;
  /** The Key of the client account (root client) is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /** Client Partners assigned to the Client. */
  clientPartners: Array<LegacyUser>;
  /** Creative Specialists assigned to the Client. */
  creativeSpecialists: Array<LegacyUser>;
  /** Description of the Client and the service they offer. */
  description?: Maybe<Scalars['String']>;
  /** Facebook URL relevant to the Client. */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The feature flags of the Client. */
  featureFlags: Array<FeatureFlag>;
  /** Hubspot Company ID linking the record to Shuttlerock's sales database. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Client. */
  id: Scalars['ID'];
  /** Instagram URL relevant to the Client. */
  instagramUrl?: Maybe<Scalars['String']>;
  /** A surrogate UUID used to identify a client when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Flag used to determine if the client allows logged out users to download their deliverables. */
  loggedOutDownloading: Scalars['Boolean'];
  /** The maximum number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables: Scalars['Int'];
  /** The name of the Client. */
  name: Scalars['String'];
  /** The number of available brands for this client. */
  numBrands: Scalars['Int'];
  /** The number of groups for this client. */
  numGroups: Scalars['Int'];
  /** Other URL relevant to the Client. */
  otherUrl?: Maybe<Scalars['String']>;
  permittedAttributes: Scalars['JSON'];
  /** Pinterest URL relevant to the Client. */
  pinterestUrl?: Maybe<Scalars['String']>;
  /** Platforms assigned to the Client. */
  platforms: Array<Platform>;
  /** Flag used to determine if the client allows deliverables to be downloaded before they have been approved. */
  preApprovalDownloading: Scalars['Boolean'];
  /** Production Managers assigned to the Client. */
  productionManagers: Array<LegacyUser>;
  /** The sales region of the Client. */
  region: Region;
  /** Hubspot Subscription ID linking the record to Shuttlerock's sales database. */
  subscriptionId?: Maybe<Scalars['BigInt']>;
  /** Tiktok URL relevant to the Client. */
  tiktokUrl?: Maybe<Scalars['String']>;
  /** Twitter URL relevant to the Client. */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The type of the client */
  type: ClientType;
  /**
   * Users belonging to the Client.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  users: Users;
  /** Industry category used to define the Client. */
  vertical?: Maybe<Vertical>;
  /** Website URL relevant to the Client. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** Youtube URL relevant to the Client. */
  youtubeUrl?: Maybe<Scalars['String']>;
};


export type ClientGroupAncestorArgs = {
  ancestryDepth?: InputMaybe<Scalars['Int']>;
};


export type ClientGroupChildrenArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


export type ClientGroupUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};

export enum ClientType {
  DirectClient = 'DirectClient',
  EnterpriseBrandClient = 'EnterpriseBrandClient',
  EnterpriseClient = 'EnterpriseClient',
  EnterpriseGroup = 'EnterpriseGroup',
  EnterpriseRegion = 'EnterpriseRegion'
}

/** A container for arguments used when updating a client. */
export type ClientUpdateInput = {
  /** The account type of the client. */
  accountType?: InputMaybe<AccountType>;
  /** An array of asset sources that can be considered to supplement the provided assets. */
  assetSources?: InputMaybe<Array<Scalars['String']>>;
  /** The expiry date of the hero balance of a License Plus account type. */
  balanceExpiresAt?: InputMaybe<Scalars['ISO8601Date']>;
  /** Brand guidelines URL relevant to the client. */
  brandGuidelinesUrl?: InputMaybe<Scalars['String']>;
  /** The brand notes of the client. */
  brandNotes?: InputMaybe<EncodedString>;
  /** An array of user UUIDs to be assigned as client partners for this client. */
  clientPartnerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** An array of user UUIDs to be assigned as creative specialists for this client. */
  creativeSpecialistIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A brief description of the client and the service they offer. */
  description?: InputMaybe<Scalars['String']>;
  /** Facebook URL relevant to the client. */
  facebookUrl?: InputMaybe<Scalars['String']>;
  /** The feature flags of the client. */
  featureFlags?: InputMaybe<Array<FeatureFlag>>;
  /** The hero balance available for a License Plus account type. */
  heroBalance?: InputMaybe<Scalars['Int']>;
  /** The Hubspot ID used to link the record to Shuttlerock's sales database. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /**
   * UUID of the target client.
   * @deprecated Use integrationKey instead
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Instagram URL relevant to the client. */
  instagramUrl?: InputMaybe<Scalars['String']>;
  /** SRC integration for the target client. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** A flag to determine if deliverables for this client can be downloaded by logged out users. */
  loggedOutDownloading?: InputMaybe<Scalars['Boolean']>;
  /** The number of active deliverables allowed for a subscription account type. */
  maxActiveDeliverables?: InputMaybe<Scalars['Int']>;
  /** The number of active heroes allowed for a License account type. */
  maxActiveHeroes?: InputMaybe<Scalars['Int']>;
  /** The name of the client. */
  name?: InputMaybe<Scalars['String']>;
  /** Other URL relevant to the client. */
  otherUrl?: InputMaybe<Scalars['String']>;
  /**
   * The UUID of the parent client.
   * @deprecated Use parent_key instead
   */
  parentId?: InputMaybe<Scalars['ID']>;
  /** SRC integration for the parent group. */
  parentKey?: InputMaybe<Scalars['ID']>;
  /** Pinterest URL relevant to the client. */
  pinterestUrl?: InputMaybe<Scalars['String']>;
  /** Platforms to be made available to the client. */
  platformIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A flag to determine if the client may download deliverables prior to approval. */
  preApprovalDownloading?: InputMaybe<Scalars['Boolean']>;
  /** An array of user UUIDs to be assigned as production manager for this client. */
  productionManagerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The production notes of the client. */
  productionNotes?: InputMaybe<EncodedString>;
  /** The name of the sales region that the client belongs to. */
  region?: InputMaybe<Scalars['String']>;
  /** Tiktok URL relevant to the client. */
  tiktokUrl?: InputMaybe<Scalars['String']>;
  /** Twitter URL relevant to the client. */
  twitterUrl?: InputMaybe<Scalars['String']>;
  /** The UUID of the industry vertical the client belongs to (i.e: travel, education). */
  verticalId?: InputMaybe<Scalars['ID']>;
  /** Website URL relevant to the client. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Youtube URL relevant to the client. */
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

/** A paginated array of Clients */
export type Clients = {
  __typename?: 'Clients';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Clients */
  nodes: Array<Client>;
};

/** Autogenerated input type of CompleteOrder */
export type CompleteOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be completed. */
  order: OrderCompleteInput;
};

/** Autogenerated return type of CompleteOrder. */
export type CompleteOrderPayload = {
  __typename?: 'CompleteOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The completed order, returned for the purpose of specifying what was completed. */
  order?: Maybe<Order>;
};

/** A Concept. */
export type Concept = {
  __typename?: 'Concept';
  /** Whether the concept contains AI. */
  containsAi: Scalars['Boolean'];
  /** The concept key. */
  key: Scalars['ID'];
};

/** A container for arguments used when duplicating a concept. */
export type ConceptDuplicateInput = {
  /**
   * UUID of the target concept.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** IntegrationKey of the target concept. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

export type ConceptLite = {
  __typename?: 'ConceptLite';
  /** The integration key of the concept. */
  key: Scalars['ID'];
};

/** A container for arguments used when creating a concept variation */
export type ConceptVariationCreateInput = {
  /** Integration Key to assign to the new concept. */
  conceptIntegrationKey: Scalars['ID'];
  /** Name of the Concept Variation. */
  name: Scalars['String'];
  /** Integration Key for the source concept being varied. */
  sourceConceptIntegrationKey: Scalars['ID'];
};

/** Autogenerated input type of ConfirmOrder */
export type ConfirmOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * UUID of the order to be confirmed.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order to be confirmed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ConfirmOrder. */
export type ConfirmOrderPayload = {
  __typename?: 'ConfirmOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The confirmed order. */
  order?: Maybe<Order>;
};

/** A copilot status. */
export enum CopilotStatus {
  Completed = 'completed',
  Failed = 'failed',
  Generating = 'generating'
}

/** A Copy Suggestion. */
export type CopySuggestion = {
  __typename?: 'CopySuggestion';
  /** The UUID of the Copy Suggestion. */
  id: Scalars['ID'];
  /** A list of previous responses. */
  responses?: Maybe<Array<Scalars['String']>>;
  /** The generated copy. */
  value: Scalars['String'];
};

/** Autogenerated input type of CreateAdvert */
export type CreateAdvertInput = {
  /** Details of the advert to be created. */
  advert: AdvertCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAdvert. */
export type CreateAdvertPayload = {
  __typename?: 'CreateAdvertPayload';
  /** The new advert. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateAssets */
export type CreateAssetsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  input: BulkAssetsInput;
};

/** Autogenerated return type of CreateAssets. */
export type CreateAssetsPayload = {
  __typename?: 'CreateAssetsPayload';
  /** The newly created assets */
  assets: Array<Asset>;
  /** The Client Brand the assets were added to. */
  client?: Maybe<ClientBrand>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order the assets were added to. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of CreateBrandLogo */
export type CreateBrandLogoInput = {
  asset: BrandLogoCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBrandLogo. */
export type CreateBrandLogoPayload = {
  __typename?: 'CreateBrandLogoPayload';
  asset?: Maybe<Asset>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateClient */
export type CreateClientInput = {
  /** Details of the client to be created. */
  client: ClientCreateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateClient. */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  /** The new client. */
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateConceptVariation */
export type CreateConceptVariationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Details of the concept variation to be created. */
  variation: ConceptVariationCreateInput;
};

/** Autogenerated return type of CreateConceptVariation. */
export type CreateConceptVariationPayload = {
  __typename?: 'CreateConceptVariationPayload';
  /** The new concept variation. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCopyVariation */
export type CreateCopyVariationInput = {
  /**
   * The ID of the advert
   * @deprecated Use `advertIntegrationKey` instead.
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The integration key of the advert */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCopyVariation. */
export type CreateCopyVariationPayload = {
  __typename?: 'CreateCopyVariationPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  copyVariation?: Maybe<Advert>;
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the Order to be created. */
  order: OrderCreateInput;
};

/** Autogenerated return type of CreateOrder. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The new Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of CreateProductionDeliveryTurnaround */
export type CreateProductionDeliveryTurnaroundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Details of the delivery turnaround to be created. */
  productionDeliveryTurnaround: ProductionDeliveryTurnaroundCreateInput;
};

/** Autogenerated return type of CreateProductionDeliveryTurnaround. */
export type CreateProductionDeliveryTurnaroundPayload = {
  __typename?: 'CreateProductionDeliveryTurnaroundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The result of the mutation. */
  result: Scalars['Boolean'];
};

export enum CreativeGroup {
  Banner = 'banner',
  Beta = 'beta',
  CreativeLabs = 'creative_labs',
  Interactive = 'interactive',
  Social = 'social'
}

/** Autogenerated input type of DeliverOrder */
export type DeliverOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the order to be delivered. */
  id: Scalars['ID'];
};

/** Autogenerated return type of DeliverOrder. */
export type DeliverOrderPayload = {
  __typename?: 'DeliverOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The delivered order. */
  order?: Maybe<Order>;
};

/** A deliverable asset produced for an Order. */
export type Deliverable = {
  __typename?: 'Deliverable';
  /** The Advert associated to the Deliverable asset. */
  advert?: Maybe<Advert>;
  /** The URL of the Audio Asset associated to the Deliverable. */
  audioUrl?: Maybe<Scalars['String']>;
  /** The bitrate for a Deliverable asset, if it is a video. */
  bitrate?: Maybe<Scalars['Int']>;
  /** The bitrate mode for a Deliverable, if it is a video. */
  bitrateMode?: Maybe<Scalars['String']>;
  /** The category the deliverable belongs to. E.g. copy_variations, hero_and_formats, etc. */
  category: DeliverableCategory;
  /** The Cloudflare video ID. */
  cfVideoId?: Maybe<Scalars['String']>;
  /** Indication of whether the deliverable contains AI. */
  containsAi: Ternary;
  /** The Copy Variation Number associated to the Deliverable asset. */
  copyVariationNumber: Scalars['Int'];
  /** Deliverable Format associated with the Deliverable asset. */
  deliverableFormat: DeliverableFormat;
  /** The duration in seconds for video deliverables. */
  duration: Scalars['Float'];
  /** A secondary file name for the Deliverable when exporting, typically used for client-specific file naming conventions. */
  exportFileName?: Maybe<Scalars['String']>;
  /** The original name of the Deliverable file when it was uploaded. */
  fileName: Scalars['String'];
  /** The file size of the Deliverable file in bytes. */
  fileSize: Scalars['Int'];
  /** File extension for the Deliverable asset. */
  fileType: DeliverableFileType;
  /** The format length category for the Deliverable asset. */
  formatLength: FormatLength;
  /** The Frame rate of the Deliverable asset, if it is a video. */
  framesPerSecond?: Maybe<Scalars['Float']>;
  /** The actual height of the Deliverable, extracted from the file when uploaded. */
  height?: Maybe<Scalars['Int']>;
  /** Flags the Deliverable asset as the hero Deliverable for that order. */
  hero: Scalars['Boolean'];
  /** Checks if the deliverable is an approved hero deliverable. */
  heroApproved?: Maybe<Scalars['Boolean']>;
  /** The UUID of the Deliverable. */
  id: Scalars['ID'];
  /**
   * A surrogate UUID used to identify a deliverable when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** A surrogate UUID used to identify a deliverable when integrating with external apps. */
  key: Scalars['ID'];
  /** The Language associated to the Deliverable asset. */
  languageCode?: Maybe<Scalars['String']>;
  /**
   * The URL of the Meta Audio Asset associated to the Deliverable.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: Maybe<Scalars['String']>;
  /** The number of pending concept revisions for a deliverable. */
  numConceptRevisions: Scalars['Int'];
  /** The number of pending revision requests for a deliverable. */
  numRevisionRequests: Scalars['Int'];
  /** The Order that the Deliverable asset was created for. */
  order: Order;
  /** Numerical position of the Deliverable in relation to the others in this order. */
  position: Scalars['Int'];
  /** The number of Revision for a Deliverable asset. */
  revision: Scalars['Int'];
  /** The UTC timestamp when the Deliverable's revisions were last changed, as an Integer. */
  revisionsUpdatedAt?: Maybe<Scalars['Int']>;
  /** The path to the file on S3. */
  s3Path: Scalars['String'];
  /** Integration key for an associated Specification. */
  specificationIntegrationKey?: Maybe<Scalars['ID']>;
  /** The production state of the Deliverable. */
  state: DeliverableState;
  /** The url to the thumbnail for this Deliverable. For images this is the same as the url field. */
  thumbnailUrl: Scalars['String'];
  /** Given name of the Deliverable, chosen by the Production team on upload. */
  title?: Maybe<Scalars['String']>;
  /** Unit number associated to the Deliverable asset to prevent it being detected as a duplicate. */
  unitNumber: Scalars['Int'];
  /** The UTC timestamp when the Deliverable was last updated, as an Integer. */
  updatedAt: Scalars['Int'];
  /** Public web address where the Deliverable asset is located. */
  url: Scalars['String'];
  /** The version number of the Deliverable. */
  versionNumberExternal?: Maybe<Scalars['Int']>;
  /** The internal version number of the Deliverable in respect to the external version number. */
  versionNumberInternal?: Maybe<Scalars['Int']>;
  /** The actual width of the Deliverable, extracted from the file when uploaded. */
  width?: Maybe<Scalars['Int']>;
};


/** A deliverable asset produced for an Order. */
export type DeliverableThumbnailUrlArgs = {
  fit?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export enum DeliverableCategory {
  CopyVariations = 'copy_variations',
  HeroAndFormats = 'hero_and_formats',
  Languages = 'languages',
  Unknown = 'unknown'
}

export enum DeliverableFileType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Mp4 = 'MP4',
  Png = 'PNG'
}

/** A Deliverable Format. */
export type DeliverableFormat = {
  __typename?: 'DeliverableFormat';
  /** The Creative Group the DeliverableFormat belongs to. */
  creativeGroup?: Maybe<CreativeGroup>;
  /** The Dimension of the DeliverableFormat. */
  dimension?: Maybe<Dimension>;
  /** The code used to map a deliverable name to its Deliverable Format */
  filenameCode?: Maybe<Scalars['String']>;
  /** The possible lengths of deliverable. */
  formatLengths: Array<FormatLength>;
  /** The UUID of the Deliverable Format. */
  id: Scalars['ID'];
  /**
   * A surrogate UUID used to identify a deliverable format when integrating with external apps.
   * @deprecated Use `key` instead.
   */
  integrationKey: Scalars['ID'];
  /** Flag identifying deliverable formats with custom dimensions. */
  isCustom: Scalars['Boolean'];
  /** A surrogate UUID used to identify a deliverable format when integrating with external apps. */
  key: Scalars['ID'];
  /** A combination of the Deliverable Format's name and ratio. */
  label: Scalars['String'];
  /** Given name for the Deliverable Format. */
  name: Scalars['String'];
  /** The platform name. */
  platform: Platform;
  /** Determining the order to display. */
  position: Scalars['Int'];
};

/** A container for arguments used when creating an deliverable format dimension */
export type DeliverableFormatDimensionInput = {
  /** The lengths of requested deliverables. */
  formatLengths: Array<FormatLength>;
  /** The height of the dimension. */
  height?: InputMaybe<Scalars['Int']>;
  /** The width of the dimension. */
  width?: InputMaybe<Scalars['Int']>;
};

/** A paginated array of Deliverable Formats */
export type DeliverableFormats = {
  __typename?: 'DeliverableFormats';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Deliverable Formats */
  nodes: Array<DeliverableFormat>;
};

/** A container for arguments used when creating/updating a deliverable */
export type DeliverableInput = {
  /** The UUID of the Advert to be associated to this deliverable. */
  advertId: Scalars['ID'];
  /** The URL of the audio asset associated to the Deliverable asset. */
  audioUrl?: InputMaybe<Scalars['String']>;
  /** The bitrate for a Deliverable asset, if it is a video. */
  bitrate?: InputMaybe<Scalars['Int']>;
  /** The bitrate mode for a Deliverable asset if it is a video. */
  bitrateMode?: InputMaybe<Scalars['String']>;
  /** Indication of whether the deliverable contains AI. */
  containsAi?: InputMaybe<Ternary>;
  /** The Copy Variation Number associated to the Deliverable asset. */
  copyVariationNumber?: InputMaybe<Scalars['Int']>;
  /** The UUID of the deliverable format to be attached by this deliverable. */
  deliverableFormatId?: InputMaybe<Scalars['ID']>;
  /** The duration of the deliverable. */
  duration?: InputMaybe<Scalars['Float']>;
  /** A secondary file name for the Deliverable when exporting. */
  exportFileName?: InputMaybe<Scalars['String']>;
  /** The original name of the Deliverable file when it was uploaded. */
  fileName: Scalars['String'];
  /** The file size of the Deliverable file in bytes. */
  fileSize?: InputMaybe<Scalars['Int']>;
  /** The file type of the deliverable. */
  fileType?: InputMaybe<DeliverableFileType>;
  /** The format length category for the Deliverable. */
  formatLength?: InputMaybe<FormatLength>;
  /** The Frame rate of the Deliverable asset, if it is a video. */
  framesPerSecond?: InputMaybe<Scalars['Float']>;
  /** The actual height of the Deliverable, extracted from the file when uploaded. */
  height?: InputMaybe<Scalars['Int']>;
  /** Flags the Deliverable asset as the hero Deliverable for that order. */
  hero: Scalars['Boolean'];
  /** UUID of the target deliverable. */
  id?: InputMaybe<Scalars['ID']>;
  /** The Language associated to the Deliverable asset. */
  languageCode: Scalars['String'];
  /**
   * The URL of the Meta audio asset associated to the Deliverable asset.
   * @deprecated Use audio_url instead
   */
  metaAudioAssetUrl?: InputMaybe<Scalars['String']>;
  /** The position of the deliverable. */
  position?: InputMaybe<Scalars['Int']>;
  /** The number of Revision for a Deliverable asset. */
  revision?: InputMaybe<Scalars['Int']>;
  /** The S3 path of the deliverable. */
  s3Path?: InputMaybe<Scalars['String']>;
  /**
   * The title of the deliverable.
   * @deprecated Use export_file_name instead.
   */
  title?: InputMaybe<Scalars['String']>;
  /** Unit number associated to the Deliverable asset to prevent it being detected as a duplicate. */
  unitNumber: Scalars['Int'];
  /** The actual width of the Deliverable, extracted from the file when uploaded. */
  width?: InputMaybe<Scalars['Int']>;
};

export enum DeliverableState {
  Approved = 'approved',
  ClientReview = 'client_review',
  Expired = 'expired',
  InternalReview = 'internal_review',
  InternallyRejected = 'internally_rejected',
  PendingDelivery = 'pending_delivery',
  Rejected = 'rejected'
}

/** Autogenerated input type of DeliverableUnapproved */
export type DeliverableUnapprovedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Deliverable to be unapproved. */
  deliverable: UnapprovedDeliverableInput;
};

/** Autogenerated return type of DeliverableUnapproved. */
export type DeliverableUnapprovedPayload = {
  __typename?: 'DeliverableUnapprovedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be updated. */
  deliverable?: Maybe<Deliverable>;
};

/** A container for arguments used when updating a deliverable */
export type DeliverableUpdateInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /**
   * Event sequence ID of incoming event.
   * @deprecated Related to Kinesis events. Superseded by knative and `event_id`.
   */
  eventSequenceId?: InputMaybe<Scalars['Int']>;
  /** Flags a hero deliverable as being approved. */
  heroApproved?: InputMaybe<Scalars['Boolean']>;
  /** UUID of the target deliverable. */
  id?: InputMaybe<Scalars['ID']>;
  /** A surrogate UUID used to identify a deliverable when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** Number of revisions that apply to all deliverables in the concept. */
  numConceptRevisions?: InputMaybe<Scalars['Int']>;
  /** Number of revisions requested on the deliverable. */
  numRevisionRequests?: InputMaybe<Scalars['Int']>;
  /** Integration key of the specification being associated to the deliverable. */
  specificationIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** State of the deliverable. */
  state?: InputMaybe<DeliverableState>;
  /** The external version number of the deliverable. */
  versionNumberExternal?: InputMaybe<Scalars['Int']>;
  /** The internal version number of the deliverable in respect to the external version number. */
  versionNumberInternal?: InputMaybe<Scalars['Int']>;
};

/** A container for arguments used when rejecting deliverables */
export type DeliverablesRejectInput = {
  /** The UUID of the project to be rejected. */
  id: Scalars['ID'];
  /** The priority level of a revision round. */
  priority?: InputMaybe<ProductionPriority>;
};

/** The expected delivery turnaround time. */
export type DeliveryTurnaround = {
  __typename?: 'DeliveryTurnaround';
  /** Reason the delivery time was adjusted. */
  adjustedDeliveryReason?: Maybe<Scalars['String']>;
  /** The new delivery time. */
  adjustedDeliveryTime?: Maybe<Scalars['BigInt']>;
  /** Time of delivery. */
  deliveredAt?: Maybe<Scalars['BigInt']>;
  /** The expected delivery time. */
  expectedDeliveryTime?: Maybe<Scalars['BigInt']>;
  /** Id of the delivery turnaround. */
  key: Scalars['ID'];
  /** The original delivery time before any manual updates. */
  originalDeliveryTime?: Maybe<Scalars['BigInt']>;
};

/** Autogenerated input type of DestroyAdvert */
export type DestroyAdvertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * UUID of the advert to be destroyed.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the advert to be destroyed. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of DestroyAdvert. */
export type DestroyAdvertPayload = {
  __typename?: 'DestroyAdvertPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DestroyConceptVariation */
export type DestroyConceptVariationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Concept Variation to be destroyed. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DestroyConceptVariation. */
export type DestroyConceptVariationPayload = {
  __typename?: 'DestroyConceptVariationPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A Dimension. */
export type Dimension = {
  __typename?: 'Dimension';
  /**
   * A flag indicating that the Dimension is custom.
   * @deprecated Use deliverable_format.is_custom instead
   */
  custom: Scalars['Boolean'];
  /** The Dimension height. */
  height: Scalars['Int'];
  /** The humanized ratio of the Dimension width & height, e.g. 16:9 */
  humanizedRatio: Scalars['String'];
  /** The UUID of the Dimension. */
  id: Scalars['ID'];
  /** The Dimension's human readable label in the form: <width>x<height> <humanize_ratio>. */
  label: Scalars['String'];
  /** The decimal expression of the ratio of the Dimension width and height, e.g. 1.78. */
  ratio: Scalars['Float'];
  /** The Dimension width. */
  width: Scalars['Int'];
};

/** Autogenerated input type of DiscardBrand */
export type DiscardBrandInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DiscardBrand. */
export type DiscardBrandPayload = {
  __typename?: 'DiscardBrandPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardClientAccount */
export type DiscardClientAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of DiscardClientAccount. */
export type DiscardClientAccountPayload = {
  __typename?: 'DiscardClientAccountPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DiscardDeliverable */
export type DiscardDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Deliverable to be discarded. */
  id: Scalars['ID'];
};

/** Autogenerated return type of DiscardDeliverable. */
export type DiscardDeliverablePayload = {
  __typename?: 'DiscardDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliverable?: Maybe<Deliverable>;
};

/** Autogenerated input type of DiscardOrder */
export type DiscardOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be discarded. */
  order: OrderDiscardInput;
};

/** Autogenerated return type of DiscardOrder. */
export type DiscardOrderPayload = {
  __typename?: 'DiscardOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The discarded order, returned for the purpose of specifying what was discarded. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of DiscardWorkingFile */
export type DiscardWorkingFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID integration Key of the Working File to be discarded. */
  key: Scalars['ID'];
};

/** Autogenerated return type of DiscardWorkingFile. */
export type DiscardWorkingFilePayload = {
  __typename?: 'DiscardWorkingFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  workingFile?: Maybe<WorkingFile>;
};

/** Autogenerated input type of DuplicateConcept */
export type DuplicateConceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the concept to be duplicated. */
  concept: ConceptDuplicateInput;
};

/** Autogenerated return type of DuplicateConcept. */
export type DuplicateConceptPayload = {
  __typename?: 'DuplicateConceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated concept. */
  concept?: Maybe<Advert>;
};

/** Autogenerated input type of DuplicateOrder */
export type DuplicateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be duplicated. */
  order: OrderDuplicateInput;
};

/** Autogenerated return type of DuplicateOrder. */
export type DuplicateOrderPayload = {
  __typename?: 'DuplicateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of DuplicateProject */
export type DuplicateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the project to be duplicated. */
  order: ProjectDuplicateInput;
};

/** Autogenerated return type of DuplicateProject. */
export type DuplicateProjectPayload = {
  __typename?: 'DuplicateProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The duplicated project. */
  order?: Maybe<Order>;
};

/** Represents a string that has been encoded */
export type EncodedString = {
  /** The encoded string data */
  data: Scalars['String'];
  /** The type of encoding applied to the string */
  encoding: StringEncoding;
};

export enum ErrorCode {
  InvalidArgument = 'INVALID_ARGUMENT',
  NotFound = 'NOT_FOUND',
  Unauthorised = 'UNAUTHORISED',
  DimensionRatioNotUnique = 'dimension_ratio_not_unique',
  UserNotMatch = 'user_not_match'
}

export enum FeatureFlag {
  E2ETestData = 'E2E_TEST_DATA',
  ShuttlerockImagine = 'SHUTTLEROCK_IMAGINE'
}

export enum FormatLength {
  Animated = 'ANIMATED',
  Custom = 'CUSTOM',
  Less_6S = 'LESS_6S',
  Less_10S = 'LESS_10S',
  Less_15S = 'LESS_15S',
  Less_30S = 'LESS_30S',
  Static = 'STATIC'
}

/** A container for arguments used when submitting additional formats. */
export type FormatsSubmitInput = {
  /** UUID of the project that Formats are being submitted for. */
  id: Scalars['ID'];
  /** The priority of the formats being submitted. */
  priority?: InputMaybe<ProductionPriority>;
};

/** Autogenerated input type of InternallyDeliverOrder */
export type InternallyDeliverOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the order. */
  id: Scalars['ID'];
};

/** Autogenerated return type of InternallyDeliverOrder. */
export type InternallyDeliverOrderPayload = {
  __typename?: 'InternallyDeliverOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order. */
  order?: Maybe<Order>;
};

/** Invitation details for a specific user. */
export type Invitation = {
  __typename?: 'Invitation';
  /** The UTC timestamp when the Invitation was accepted. */
  acceptedAt?: Maybe<Scalars['Int']>;
  /** The UTC timestamp when the Invitation was created. */
  createdAt: Scalars['Int'];
  /** Email address the invitation was sent to. */
  email?: Maybe<Scalars['String']>;
  /** Whether the invite email address has already been registered. */
  emailRegistered: Scalars['Boolean'];
  /** The UTC timestamp when the Invitation was expired. */
  expiredAt?: Maybe<Scalars['Int']>;
  /** Given name of the invited user. */
  firstName?: Maybe<Scalars['String']>;
  /** The UUID of the Invitation. */
  id: Scalars['ID'];
  /** Family name of the invited user. */
  lastName?: Maybe<Scalars['String']>;
  /** The UTC timestamp when the Invitation was sent. */
  sentAt?: Maybe<Scalars['Int']>;
  /** The current state of the Invitation. */
  state: InvitationState;
  /** Unique token used to redeem the Invitation. */
  token?: Maybe<Scalars['String']>;
  /** URL used to sign up to SRC as the user associated to the Invitation. */
  url?: Maybe<Scalars['String']>;
};

/** A container for arguments used when accepting an invitation */
export type InvitationAcceptInput = {
  /** Auth0 integration key to be associated to the accepting user. */
  auth0UserId: Scalars['String'];
  /** The UUID of the invitation to be accepted. */
  id: Scalars['ID'];
};

/** A container for arguments used when resending an invite to a User. */
export type InvitationResendInput = {
  /** UUID of the User to re-invite. */
  id: Scalars['ID'];
  /** Flag indicating whether to skip sending an invitation email to the new user. Defaults to false. */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

/** An invitation state. */
export enum InvitationState {
  Accepted = 'accepted',
  Expired = 'expired',
  Pending = 'pending'
}

/** Autogenerated input type of InviteNewUser */
export type InviteNewUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be invited. */
  invite: InviteUserInput;
};

/** Autogenerated return type of InviteNewUser. */
export type InviteNewUserPayload = {
  __typename?: 'InviteNewUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The invited User. */
  user?: Maybe<LegacyUser>;
};

/** A container for arguments used when inviting a new user */
export type InviteUserInput = {
  /**
   * UUID of the Client to add the new User to.
   * @deprecated Use `clientIntegrationKey` instead.
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Client to add the new User to. */
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Email address of the invited User, where an invitation email will be sent. */
  email: Scalars['String'];
  /** Given name of the new User. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Family name of the new User. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Flag indicating whether to skip sending an invitation email to the new user. Defaults to false. */
  skipEmail?: InputMaybe<Scalars['Boolean']>;
};

/** A paginated array of Deliverables */
export type LegacyDeliverables = {
  __typename?: 'LegacyDeliverables';
  /** The total number of Deliverables */
  count: Scalars['Int'];
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Deliverables */
  nodes: Array<Deliverable>;
};

/** A User. */
export type LegacyUser = {
  __typename?: 'LegacyUser';
  /** The number of active deliverables a Subscription user currently has. */
  activeDeliverables?: Maybe<Scalars['Int']>;
  /** The number of active hero deliverables a License user currently has. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** The Key of the Client Account the user is associated with. */
  clientAccountIntegrationKey?: Maybe<Scalars['ID']>;
  /**
   * Clients the User has access to. (Note that we filter these by those the requesting User has access to).
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  clients: Clients;
  /** The UTC timestamp for the User's creation, as an Integer. */
  createdAt: Scalars['Int'];
  /** Email address belonging to the user, sourced from Auth0. */
  email?: Maybe<Scalars['String']>;
  /** The feature flags of the User. */
  featureFlags: Array<FeatureFlag>;
  /** Given name of the user, sourced from Auth0 */
  firstName?: Maybe<Scalars['String']>;
  /** Full name of the user, sourced from Auth0 */
  fullName?: Maybe<Scalars['String']>;
  /** The UUID of the User. */
  id: Scalars['ID'];
  /** The integration key of the User. */
  integrationKey: Scalars['ID'];
  /** The Invitation details of the User */
  invitation?: Maybe<Invitation>;
  /** Family name of the user, sourced from Auth0 */
  lastName?: Maybe<Scalars['String']>;
  /** Flag indicating whether the user is a License user or not. */
  license?: Maybe<Scalars['Boolean']>;
  /** Flag indicating whether the user is a License Plus user or not. */
  licensePlus?: Maybe<Scalars['Boolean']>;
  /** The total number of active deliverables allowed for a Subscription user. */
  maxActiveDeliverables?: Maybe<Scalars['Int']>;
  /** The total number of active heroes allowed for a License user. */
  maxActiveHeroes?: Maybe<Scalars['Int']>;
  /**
   * Orders submitted by the User. (Note that we filter these by those the requesting User has access to).
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  /** Auth0 picture attached to the user */
  picture?: Maybe<Scalars['String']>;
  /** Studio the User belongs to. */
  studio?: Maybe<Studio>;
  /** Flag indicating whether the user is a Subscription user or not. */
  subscription?: Maybe<Scalars['Boolean']>;
};


/** A User. */
export type LegacyUserClientsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** A User. */
export type LegacyUserOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};

/** Pagination metadata for paginated content */
export type Metadata = {
  __typename?: 'Metadata';
  /** The current page number */
  currentPage: Scalars['Int'];
  /** The number of results returned on the current page */
  currentResults: Scalars['Int'];
  /** Whether or not there are more pages to be fetched */
  hasMore: Scalars['Boolean'];
  /** The total number of pages */
  numPages: Scalars['Int'];
  /** The total number of results */
  numResults: Scalars['Int'];
  /** The maximum number of results returned per page */
  perPage: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept an invitation to join Shuttlerock Cloud. Invitations can only be used once. */
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  /**
   * Add a format to a concept.
   *
   * Requires authorization.
   */
  addDeliverableFormatToConcept?: Maybe<AddDeliverableFormatToConceptPayload>;
  /**
   * Associate a Specification to a Deliverable.
   *
   * Requires authorization.
   */
  addSpecificationToDeliverable?: Maybe<AddSpecificationToDeliverablePayload>;
  /**
   * Answer Questions in bulk during Order briefing.
   *
   * Requires authorization.
   */
  answerQuestions?: Maybe<AnswerQuestionsPayload>;
  /**
   * Approve an Order and its deliverables.
   *
   * Requires authorization.
   */
  approveDeliverables?: Maybe<ApproveDeliverablesPayload>;
  /** Archive an asset */
  archiveAsset?: Maybe<ArchiveAssetPayload>;
  /** Archives assets from an order */
  archiveAssets?: Maybe<ArchiveAssetsPayload>;
  /**
   * Submit a brief to the creative team for asset production.\n\nRequires
   * authorization, should only be called via event handler.
   */
  briefSubmittedForAssetProduction?: Maybe<BriefSubmittedForAssetProductionPayload>;
  /**
   * Submit a brief to the customer representative for review.\n\nThe brief will
   * still be editable after submission.\n\nOn submission, the submitting user will
   * be attributed to the order.\n\nRequires authorization, should only be called
   * via event handler.
   */
  briefSubmittedForCsReview?: Maybe<BriefSubmittedForCsReviewPayload>;
  /**
   * Confirms a briefs CS Review state and moves the project state from cs_review
   * to production_review.Requires authorization, should only be called via event handler.
   */
  briefSubmittedForProductionReview?: Maybe<BriefSubmittedForProductionReviewPayload>;
  /**
   * Submit a brief to the creative team for ugc ideation.\n\nThe brief will still
   * be editable after submission.\n\nOn submission, the submitting user will be
   * attributed to the order.\n\nRequires authorization, should only be called via event handler.
   */
  briefSubmittedForUgcIdeation?: Maybe<BriefSubmittedForUgcIdeationPayload>;
  /**
   * Cleanup E2E test data.
   *
   * Requires authorization.
   */
  cleanupE2eTestData?: Maybe<CleanupE2eTestDataPayload>;
  /**
   * Complete an order in progress or in client review.
   *
   * Requires authorization.
   */
  completeOrder?: Maybe<CompleteOrderPayload>;
  /** Confirm an brief for processing. Note that once confirmed the order will no longer be editable.Requires authorization. */
  confirmOrder?: Maybe<ConfirmOrderPayload>;
  /**
   * Create a new Advert.
   *
   * Requires authorization.
   */
  createAdvert?: Maybe<CreateAdvertPayload>;
  /**
   * Create multiple new assets for a given client or order.
   *
   * Requires authorization.
   */
  createAssets?: Maybe<CreateAssetsPayload>;
  /** Create a brand logo asset for a given client and deletes the old one */
  createBrandLogo?: Maybe<CreateBrandLogoPayload>;
  /**
   * Create a new client (brand or enterprise node).
   *
   * Requires authorization.
   */
  createClient?: Maybe<CreateClientPayload>;
  /**
   * Create a new concept variation.
   *
   * Requires authorization.
   */
  createConceptVariation?: Maybe<CreateConceptVariationPayload>;
  /**
   * Create a copy variation for an Advert.
   *
   * Requires authorization.
   */
  createCopyVariation?: Maybe<CreateCopyVariationPayload>;
  /**
   * Create a new Order.
   *
   * Requires authorization.
   */
  createOrder?: Maybe<CreateOrderPayload>;
  /**
   * Create a new Production Delivery Turnaround for a project
   *
   * Requires authorization.
   */
  createProductionDeliveryTurnaround?: Maybe<CreateProductionDeliveryTurnaroundPayload>;
  /**
   * Delivers an order. Once delivered, the client can review deliverables.
   *
   * Note that an order can only be delivered with this mutation if it is in Internal revision with Shuttlerock staff.
   *
   * Requires authorization.
   */
  deliverOrder?: Maybe<DeliverOrderPayload>;
  /**
   * Unapprove an approved deliverable.
   *
   * Requires authorization.
   */
  deliverableUnapproved?: Maybe<DeliverableUnapprovedPayload>;
  /**
   * Destroys an advert.
   *
   * Requires authorization.
   */
  destroyAdvert?: Maybe<DestroyAdvertPayload>;
  /**
   * Destroys a Concept Variation.
   *
   * Requires authorization.
   */
  destroyConceptVariation?: Maybe<DestroyConceptVariationPayload>;
  /**
   * Discard a brand.
   *
   * Requires authorization.
   */
  discardBrand?: Maybe<DiscardBrandPayload>;
  /**
   * Discard a client account.
   *
   * Requires authorization.
   */
  discardClientAccount?: Maybe<DiscardClientAccountPayload>;
  /**
   * Destroys a Deliverable.
   *
   * Requires authorization.
   */
  discardDeliverable?: Maybe<DiscardDeliverablePayload>;
  /**
   * Discard a drafted order. Submitted orders cannot be discarded.
   *
   * Requires authorization.
   */
  discardOrder?: Maybe<DiscardOrderPayload>;
  /**
   * Discards a Working File.
   *
   * Requires authorization.
   */
  discardWorkingFile?: Maybe<DiscardWorkingFilePayload>;
  /**
   * Duplicates a concept - accessible to users that can edit the parent project.
   *
   * Requires authorization.
   */
  duplicateConcept?: Maybe<DuplicateConceptPayload>;
  /**
   * Duplicates an order to the given client (defaults to same client) - accessible to all users that can create a project.
   *
   * Requires authorization.
   */
  duplicateOrder?: Maybe<DuplicateOrderPayload>;
  /**
   * Duplicates a project to the same client, including answers and assets - accessible to all users that can create a project.
   *
   * Requires authorization.
   */
  duplicateProject?: Maybe<DuplicateProjectPayload>;
  /**
   * Deliver internally an order.
   *
   * Requires authorization.
   */
  internallyDeliverOrder?: Maybe<InternallyDeliverOrderPayload>;
  /**
   * Invite a new User to an existing Client.
   *
   * Requires authorization.
   */
  inviteNewUser?: Maybe<InviteNewUserPayload>;
  /**
   * Reject an Order and its deliverables.
   *
   * Requires authorization.
   */
  rejectDeliverables?: Maybe<RejectDeliverablesPayload>;
  /**
   * Remove deliverable formats from a concept.
   *
   * Requires authorization.
   */
  removeDeliverableFormatsFromConcept?: Maybe<RemoveDeliverableFormatsFromConceptPayload>;
  /**
   * Remove a user from an existing Client account.
   *
   * Requires authorization.
   */
  removeUser?: Maybe<RemoveUserPayload>;
  /**
   * Replace an already existing deliverable.
   *
   * Requires authorization.
   */
  replaceDeliverable?: Maybe<ReplaceDeliverablePayload>;
  /**
   * Resend an invite email to a User.
   *
   * Requires authorization.
   */
  resendInvitation?: Maybe<ResendInvitationPayload>;
  /** Revert a brief to UGC Ideation.\n\nRequires authorization, should only be called via event handler. */
  revertBriefToUgcIdeation?: Maybe<RevertBriefToUgcIdeationPayload>;
  /**
   * Revise an Order.
   *
   * Requires authorization.
   */
  reviseOrder?: Maybe<ReviseOrderPayload>;
  /** Add notes to an asset. */
  setAssetNotes?: Maybe<SetAssetNotesPayload>;
  /**
   * Updates the generation status .
   *
   * Requires authorization.
   */
  setOrderCopilotStatus?: Maybe<SetOrderCopilotStatusPayload>;
  /** Set a projects target date using the given ID. */
  setOrderTargetDate?: Maybe<SetOrderTargetDatePayload>;
  /**
   * Submit additional formats for an order.
   *
   * Requires authorization.
   */
  submitFormats?: Maybe<SubmitFormatsPayload>;
  /**
   * Submit an order for processing. Note that once submitted the order will no
   * longer be editable.On submission, the submitting user will be attributed to the order.
   *
   * Requires authorization.
   */
  submitOrder?: Maybe<SubmitOrderPayload>;
  /**
   * Update the Advert with the given ID.
   *
   * Requires authorization.
   */
  updateAdvert?: Maybe<UpdateAdvertPayload>;
  /**
   * Update an auth0_integration_key for an existing user.
   *
   * Requires authorization.
   */
  updateAuth0IntegrationKey?: Maybe<UpdateAuth0IntegrationKeyPayload>;
  /**
   * Update the client with the given ID.
   *
   * Requires authorization.
   */
  updateClient?: Maybe<UpdateClientPayload>;
  /**
   * Update an existing deliverable.
   *
   * Requires authorization.
   */
  updateDeliverable?: Maybe<UpdateDeliverablePayload>;
  /** Trigger update of a project's delivery turnaround time. */
  updateDeliveryTurnaround?: Maybe<UpdateDeliveryTurnaroundPayload>;
  /**
   * Update an existing order.
   *
   * Requires authorization.
   */
  updateOrder?: Maybe<UpdateOrderPayload>;
  /**
   * Update the deliverables of an existing order.
   *
   * Requires authorization.
   */
  updateOrderDeliverables?: Maybe<UpdateOrderDeliverablesPayload>;
  /**
   * Update the order's translations with the given ID.
   *
   * Requires authorization.
   */
  updateTranslations?: Maybe<UpdateTranslationsPayload>;
  /**
   * Update a trello card belonging to the order with the given ID.
   *
   * Requires authorization.
   */
  updateTrello?: Maybe<UpdateTrelloPayload>;
  /** Trigger update of labels on a project's Trello card. */
  updateTrelloLabels?: Maybe<UpdateTrelloLabelsPayload>;
  /** Update the current user. */
  updateUser?: Maybe<UpdateUserPayload>;
};


export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


export type MutationAddDeliverableFormatToConceptArgs = {
  input: AddDeliverableFormatToConceptInput;
};


export type MutationAddSpecificationToDeliverableArgs = {
  input: AddSpecificationToDeliverableInput;
};


export type MutationAnswerQuestionsArgs = {
  input: AnswerQuestionsInput;
};


export type MutationApproveDeliverablesArgs = {
  input: ApproveDeliverablesInput;
};


export type MutationArchiveAssetArgs = {
  input: ArchiveAssetInput;
};


export type MutationArchiveAssetsArgs = {
  input: ArchiveAssetsInput;
};


export type MutationBriefSubmittedForAssetProductionArgs = {
  input: BriefSubmittedForAssetProductionInput;
};


export type MutationBriefSubmittedForCsReviewArgs = {
  input: BriefSubmittedForCsReviewInput;
};


export type MutationBriefSubmittedForProductionReviewArgs = {
  input: BriefSubmittedForProductionReviewInput;
};


export type MutationBriefSubmittedForUgcIdeationArgs = {
  input: BriefSubmittedForUgcIdeationInput;
};


export type MutationCleanupE2eTestDataArgs = {
  input: CleanupE2eTestDataInput;
};


export type MutationCompleteOrderArgs = {
  input: CompleteOrderInput;
};


export type MutationConfirmOrderArgs = {
  input: ConfirmOrderInput;
};


export type MutationCreateAdvertArgs = {
  input: CreateAdvertInput;
};


export type MutationCreateAssetsArgs = {
  input: CreateAssetsInput;
};


export type MutationCreateBrandLogoArgs = {
  input: CreateBrandLogoInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateConceptVariationArgs = {
  input: CreateConceptVariationInput;
};


export type MutationCreateCopyVariationArgs = {
  input: CreateCopyVariationInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateProductionDeliveryTurnaroundArgs = {
  input: CreateProductionDeliveryTurnaroundInput;
};


export type MutationDeliverOrderArgs = {
  input: DeliverOrderInput;
};


export type MutationDeliverableUnapprovedArgs = {
  input: DeliverableUnapprovedInput;
};


export type MutationDestroyAdvertArgs = {
  input: DestroyAdvertInput;
};


export type MutationDestroyConceptVariationArgs = {
  input: DestroyConceptVariationInput;
};


export type MutationDiscardBrandArgs = {
  input: DiscardBrandInput;
};


export type MutationDiscardClientAccountArgs = {
  input: DiscardClientAccountInput;
};


export type MutationDiscardDeliverableArgs = {
  input: DiscardDeliverableInput;
};


export type MutationDiscardOrderArgs = {
  input: DiscardOrderInput;
};


export type MutationDiscardWorkingFileArgs = {
  input: DiscardWorkingFileInput;
};


export type MutationDuplicateConceptArgs = {
  input: DuplicateConceptInput;
};


export type MutationDuplicateOrderArgs = {
  input: DuplicateOrderInput;
};


export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput;
};


export type MutationInternallyDeliverOrderArgs = {
  input: InternallyDeliverOrderInput;
};


export type MutationInviteNewUserArgs = {
  input: InviteNewUserInput;
};


export type MutationRejectDeliverablesArgs = {
  input: RejectDeliverablesInput;
};


export type MutationRemoveDeliverableFormatsFromConceptArgs = {
  input: RemoveDeliverableFormatsFromConceptInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationReplaceDeliverableArgs = {
  input: ReplaceDeliverableInput;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


export type MutationRevertBriefToUgcIdeationArgs = {
  input: RevertBriefToUgcIdeationInput;
};


export type MutationReviseOrderArgs = {
  input: ReviseOrderInput;
};


export type MutationSetAssetNotesArgs = {
  input: SetAssetNotesInput;
};


export type MutationSetOrderCopilotStatusArgs = {
  input: SetOrderCopilotStatusInput;
};


export type MutationSetOrderTargetDateArgs = {
  input: SetOrderTargetDateInput;
};


export type MutationSubmitFormatsArgs = {
  input: SubmitFormatsInput;
};


export type MutationSubmitOrderArgs = {
  input: SubmitOrderInput;
};


export type MutationUpdateAdvertArgs = {
  input: UpdateAdvertInput;
};


export type MutationUpdateAuth0IntegrationKeyArgs = {
  input: UpdateAuth0IntegrationKeyInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateDeliverableArgs = {
  input: UpdateDeliverableInput;
};


export type MutationUpdateDeliveryTurnaroundArgs = {
  input: UpdateDeliveryTurnaroundInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrderDeliverablesArgs = {
  input: UpdateOrderDeliverablesInput;
};


export type MutationUpdateTranslationsArgs = {
  input: UpdateTranslationsInput;
};


export type MutationUpdateTrelloArgs = {
  input: UpdateTrelloInput;
};


export type MutationUpdateTrelloLabelsArgs = {
  input: UpdateTrelloLabelsInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The campaign objective of an order or advert. */
export enum Objective {
  BrandAwareness = 'brand_awareness',
  Consideration = 'consideration',
  DirectResponse = 'direct_response',
  FullFunnel = 'full_funnel',
  Unknown = 'unknown'
}

/** An order. */
export type Order = {
  __typename?: 'Order';
  /** The sum of active hero deliverables for all adverts. */
  activeHeroes?: Maybe<Scalars['Int']>;
  /** Flags whether the project is an active project or not. */
  activeProject?: Maybe<Scalars['Boolean']>;
  /**
   * Adverts belonging to the Order.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  adverts: Adverts;
  /** Answers submitted to the Order. */
  answers?: Maybe<Answers>;
  /** The art director associated with the Order. */
  artDirector?: Maybe<LegacyUser>;
  /**
   * Assets attached to the Order
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  /** The web location of the zipped assets. */
  assetsZipUrl?: Maybe<Scalars['String']>;
  /** The UTC timestamp for when the Brief was approved, as an Integer. */
  briefApprovedAt?: Maybe<Scalars['BigInt']>;
  /**
   * Campaign questions for the Order.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  campaignQuestions: Array<Question>;
  /** Checks if deliverables can be added to the project. */
  canAddDeliverables?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be completed. */
  canBeCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks if the project can be approved. */
  canBeConfirmed?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be continued. */
  canBeContinued?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be deleted. */
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks by the projects effective state if it has been delivered internally. */
  canBeDelivered?: Maybe<Scalars['Boolean']>;
  /** Checks if the order can be duplicated matching the order structure without matching most fields. */
  canBeDuplicatedOrder?: Maybe<Scalars['Boolean']>;
  /** Checks if the project can be duplicated matching majority of its fields. */
  canBeDuplicatedProject?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders brief can be modified. */
  canEditBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders brief-formats can be modified. */
  canEditBriefFormats?: Maybe<Scalars['Boolean']>;
  /** Checks if the revision limit of the project can be adjusted */
  canHaveRevisionLimitAdjusted?: Maybe<Scalars['Boolean']>;
  /** Checks if it's possible to attempt submitting the orders brief. */
  canSubmitBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if the delivery turnaround time can be adjusted. */
  canUpdateDeliveryTurnaround: Scalars['Boolean'];
  /** Checks if the orders deliverables can be viewed. */
  canViewDeliverables?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders production brief can be accessed. */
  canViewProductionBrief?: Maybe<Scalars['Boolean']>;
  /** Checks if the orders summary brief can be accessed. */
  canViewSummaryBrief?: Maybe<Scalars['Boolean']>;
  /** The Client associated with the Order. */
  client: ClientBrand;
  /** The sequence number of an order for a specific Client */
  clientSequenceNumber: Scalars['Int'];
  /** The web location of the zipped combined project files. */
  combinedZipUrl?: Maybe<Scalars['String']>;
  /** The UTC timestamp for the Order's completion, as an Integer. */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** The copilot status of the Order. */
  copilotStatus?: Maybe<CopilotStatus>;
  /** The UTC timestamp for the Order's creation, as an Integer. */
  createdAt: Scalars['BigInt'];
  /** The current Workflow Step of the Order. */
  currentStep?: Maybe<WorkflowStep>;
  /** AWS credentials for deliverables */
  deliverableAwsCredentials: Scalars['Credential'];
  /** An array of the copy variation numbers of deliverables attached to the Order. */
  deliverableCopyVariations?: Maybe<Array<Scalars['Int']>>;
  /** The durations of the order's deliverables. */
  deliverableDurations?: Maybe<Array<FormatLength>>;
  /**
   * Deliverable formats associated with the Order
   *
   * Permitted search attributes: ["id", "integration_key", "filter_name", "length_name", "name", "platform_name"]
   *
   * Permitted sort attributes: ["created_at", "name", "position"]
   */
  deliverableFormats: DeliverableFormats;
  /** The languages associated to the deliverables of this order. */
  deliverableLanguageCodes?: Maybe<Array<Scalars['String']>>;
  /** The platforms associated to the deliverable formats of this order. */
  deliverablePlatforms?: Maybe<Array<Platform>>;
  /** Deliverables attached to the Order. */
  deliverables: LegacyDeliverables;
  /** The web location of the zipped deliverables. */
  deliverablesZipUrl?: Maybe<Scalars['String']>;
  /**
   * The UTC timestamp for the Order delivery due date, as an Integer.
   * @deprecated Use delivery_turnaround instead.
   */
  deliveryDueAt?: Maybe<Scalars['BigInt']>;
  /** The project's delivery turnaround times. */
  deliveryTurnaround?: Maybe<DeliveryTurnaround>;
  /** The effective state of the Order, varied by the current user's role. */
  effectiveState: OrderState;
  /** Link to the google drive folder associated with the Order. */
  googleDriveUrl?: Maybe<Scalars['String']>;
  /** The hubspot deal id added by internal users when creating a new order. */
  hubspotId?: Maybe<Scalars['BigInt']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify an order when integrating with external apps. */
  integrationKey: Scalars['ID'];
  /** A flag indicating whether this project qualifies for express delivery. */
  isExpress: Scalars['Boolean'];
  /** A flag indicating whether the Order is in its final workflow step. */
  isFinalStep: Scalars['Boolean'];
  /** Flags if the client is of license account type. */
  license: Scalars['Boolean'];
  /** Flags if the client is of license plus account type. */
  licensePlus: Scalars['Boolean'];
  /** The maximum number of revisions that can be submitted for the Order. */
  maxNumRevisions?: Maybe<Scalars['Int']>;
  /** The user-specified name for the Order. */
  name?: Maybe<Scalars['String']>;
  /** The next step in the order's workflow step chain. */
  nextStep?: Maybe<WorkflowStep>;
  /** The current number of revisions that have been submitted for the Order. */
  numRevisions: Scalars['Int'];
  /** The campaign objective of the order. */
  objective: Objective;
  /** The production notes of the Order. */
  productionNotes?: Maybe<Scalars['String']>;
  /** The region on the Order's Client. */
  region: Region;
  /** The UTC timestamp when the Order's revision period will end and it will automatically be approved, as an Integer. */
  revisionEndTime?: Maybe<Scalars['BigInt']>;
  /** Workflow steps which will be used for the order. */
  selectedSteps: Array<WorkflowStep>;
  /** A numeric ID unique across all Orders. */
  serialId: Scalars['String'];
  /** The web slug for the order, containing the Client name. */
  slug: Scalars['String'];
  /** The current true state of the Order. */
  state: OrderState;
  /** The submission state of the Order. */
  submissionState: SubmissionState;
  /** The UTC timestamp for the Order's submission, as an Integer. */
  submittedAt?: Maybe<Scalars['BigInt']>;
  /** Flags if the client is of subscription account type. */
  subscription: Scalars['Boolean'];
  /** A UTC timestamp for the Order's target date. */
  targetDate?: Maybe<Scalars['BigInt']>;
  /** The sum total of deliverables ordered for all adverts. */
  totalDeliverablesOrdered?: Maybe<Scalars['Int']>;
  /** The sum total of specifications ordered for all adverts. */
  totalSpecificationsOrdered?: Maybe<Scalars['Int']>;
  /** The orders Trello board based on its region. */
  trelloBoard: TrelloBoard;
  /** Link to the trello card associated with the Order. */
  trelloCardUrl?: Maybe<Scalars['String']>;
  /** The most relevant URL for the order given the current state. */
  urlForState: Scalars['String'];
  /** For draft orders, the User who created it. After submission this User is updated to the submitter. */
  user: LegacyUser;
  /**
   * Client role Users who have access to the Order
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  users: Users;
  /** The briefing flow version used to create this order. */
  version: Scalars['Int'];
  /** Working Files attached to the Order */
  workingFiles: WorkingFiles;
  /** The web location of the zipped working files. */
  workingFilesZipUrl?: Maybe<Scalars['String']>;
};


/** An order. */
export type OrderAdvertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderAnswersArgs = {
  actual?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


/** An order. */
export type OrderAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderCampaignQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** An order. */
export type OrderDeliverablesArgs = {
  fileType?: InputMaybe<DeliverableFileType>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DeliverableState>;
};


/** An order. */
export type OrderUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};


/** An order. */
export type OrderWorkingFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** A container for arguments used when completing an order. */
export type OrderCompleteInput = {
  /** UUID of the target order. */
  id: Scalars['ID'];
};

/** A container for arguments used when creating an order. */
export type OrderCreateInput = {
  /** Integration key of the Brand/EnterpriseBrand for the order to be associated with. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** Indicates that this projects brief is being ai generated. */
  generatingBrief?: InputMaybe<Scalars['Boolean']>;
  /** The hubspot deal id. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Indicates that this order should be created with a default concept. */
  withDefaultConcept?: InputMaybe<Scalars['Boolean']>;
};

/** A container for arguments used when updating the deliverables of an order. */
export type OrderDeliverablesUpdateInput = {
  /** Details of the deliverables to be updated. */
  deliverablesAttributes?: InputMaybe<Array<DeliverableInput>>;
  /** Integration key of the target order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** Storage API integration keys for working files. */
  workingFileKeys?: InputMaybe<Array<Scalars['ID']>>;
};

/** A container for arguments used when discarding an order. */
export type OrderDiscardInput = {
  /**
   * UUID of the target order.
   * @deprecated Use `integration_key` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the target project. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when duplicating an order. */
export type OrderDuplicateInput = {
  /** Integration key of the brand to duplicate to. */
  brandIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** The Hubspot ID of the project. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Integration key of the target project. */
  integrationKey: Scalars['ID'];
  /** The name of the project. */
  name?: InputMaybe<Scalars['String']>;
};

/** A container for arguments used when revising a project */
export type OrderReviseInput = {
  /** The UUID of the project to be revised. */
  id: Scalars['ID'];
  /** The priority level of a revision round. */
  priority?: InputMaybe<ProductionPriority>;
};

/** An order state. */
export enum OrderState {
  AssetProduction = 'asset_production',
  ClientReview = 'client_review',
  ClientRevision = 'client_revision',
  CsReview = 'cs_review',
  DraftBrief = 'draft_brief',
  FinishingStep = 'finishing_step',
  InProgress = 'in_progress',
  InternalReview = 'internal_review',
  InternalRevision = 'internal_revision',
  ProductionReview = 'production_review',
  ProductionSetup = 'production_setup',
  ProjectComplete = 'project_complete',
  UgcIdeation = 'ugc_ideation'
}

/** A container for arguments used when submitting an order. */
export type OrderSubmitInput = {
  /**
   * UUID of the target order.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
};

/** A container for arguments used when setting a projects target date */
export type OrderTargetDateInput = {
  /** UUID of the target order. */
  id: Scalars['ID'];
  /** The target date of a project. */
  targetDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** A container for arguments used when updating an order. */
export type OrderUpdateInput = {
  /** UUID of the art director assigned to the order. */
  artDirectorId?: InputMaybe<Scalars['ID']>;
  /**
   * UUID of the target order.
   * @deprecated Use `integrationKey` instead.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Integration key of the target order. */
  integrationKey?: InputMaybe<Scalars['ID']>;
  /** The maximum number of revision requests allowed for the order. */
  maxNumRevisions?: InputMaybe<Scalars['Int']>;
  /** The name of the order. */
  name?: InputMaybe<Scalars['String']>;
  /** The production notes of the order. */
  productionNotes?: InputMaybe<EncodedString>;
};

/** A paginated array of Orders */
export type Orders = {
  __typename?: 'Orders';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Orders */
  nodes: Array<Order>;
};

/** The input type as determined by the file types of order assets. */
export enum OriginalInputType {
  ImagesOnly = 'images_only',
  Unknown = 'unknown',
  VideoAndImages = 'video_and_images',
  VideoOnly = 'video_only'
}

/** Whitelisted attributes available to current user */
export type PermittedAttributes = {
  permittedAttributes: Scalars['JSON'];
};

/** A Platform */
export type Platform = {
  __typename?: 'Platform';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

/** A container for arguments used when creating a production delivery turnaround for a project. */
export type ProductionDeliveryTurnaroundCreateInput = {
  /** Integration Key of the production stage to which the new production delivery turnaround will be assigned. */
  productionStageIntegrationKey: Scalars['ID'];
  /** Integration Key of the project to which the new production delivery turnaround will be assigned. */
  projectIntegrationKey: Scalars['ID'];
};

/** The priority type for the production of deliverables. */
export enum ProductionPriority {
  QuickfixTier_1 = 'QUICKFIX_TIER_1',
  QuickfixTier_2 = 'QUICKFIX_TIER_2',
  Regular = 'REGULAR'
}

/** A boolean determining if a project brief is valid or not. */
export type ProjectBriefValidation = {
  __typename?: 'ProjectBriefValidation';
  integrationKey: Scalars['ID'];
  isValid: Scalars['Boolean'];
};

/** A container for arguments used when duplicating a project. */
export type ProjectDuplicateInput = {
  /** The Hubspot ID of the project. */
  hubspotId?: InputMaybe<Scalars['BigInt']>;
  /** Integration key of the target project. */
  integrationKey: Scalars['ID'];
  /** The name of the project. */
  name?: InputMaybe<Scalars['String']>;
};

/** Scopes for a given Project. */
export type ProjectScopes = {
  __typename?: 'ProjectScopes';
  canApprove: Scalars['Boolean'];
  canDestroy: Scalars['Boolean'];
  canDownloadProjectDeliverablesZip: Scalars['Boolean'];
  canModifyPostBriefFormats: Scalars['Boolean'];
  canReject: Scalars['Boolean'];
  canRevise: Scalars['Boolean'];
  canShow: Scalars['Boolean'];
  canSubmitFormats: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
  /** The UUID of the Project. */
  id: Scalars['ID'];
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /** Not implemented, this only exists due to limitations in the federation gem. */
  _concept: Concept;
  /** Not implemented, this only exists due to limitations in the federation gem. */
  _ugcBrief: UgcBrief;
  /**
   * Get all available Adverts.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "advert_id", "integration_key",
   * "order_id", "original_input_type_name",
   * "adverts_deliverable_formats_length_name"]
   *
   * Permitted sort attributes: ["position"]
   */
  adverts: Adverts;
  /**
   * Get assets.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "name", "asset_type"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  assets: Assets;
  /**
   * Get assumed role for a given auth0 integration key.
   *
   * Requires management authorization.
   */
  auth0AssumedRole: Scalars['String'];
  /**
   * Get information for a specific client.
   *
   * Requires authorization.
   */
  client: Client;
  /**
   * Get all available orders.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "account_type_name", "hubspot_id", "name",
   * "region_name", "account_type", "ancestry", "enterprise_ancestry",
   * "region_ancestry", "group_ancestry", "brands", "enterprises", "groups",
   * "region_groups", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "name", "region_name"]
   */
  clients: Clients;
  /**
   * Get copy suggestions for the given inputs.
   *
   * Requires authorization.
   */
  copySuggestions: Array<CopySuggestion>;
  /** Get a list of all deliverable format filter names. */
  deliverableFormatFilterNames: Array<Scalars['String']>;
  /**
   * Get a list of all deliverable formats.
   *
   * Permitted search attributes: ["id", "integration_key", "filter_name", "length_name", "name", "platform_name"]
   *
   * Permitted sort attributes: ["created_at", "name", "position"]
   */
  deliverableFormats: DeliverableFormats;
  /**
   * Get all the deliverables for the order deliverables page.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "duration_filter", "order_client_name",
   * "order_client_vertical_name", "order_hubspot_id", "order_name",
   * "order_region_name", "order_serial_id", "copy_variation_number",
   * "deliverable_format_id", "deliverable_format_name",
   * "deliverable_format_platform_name", "hero", "language_code", "order_best_of",
   * "order_id", "pending_review_or_approved", "hero_or_copy_variation_filter",
   * "advert_position", "full_search"]
   *
   * Permitted sort attributes: ["created_at", "order_best_of", "order_created_at", "order_completed_at"]
   */
  deliverables: LegacyDeliverables;
  /** Get all error codes. */
  errorCodes: Array<ErrorCode>;
  /** Get a specific invitation by id. */
  invitation?: Maybe<Invitation>;
  /** Get a specific async job */
  job: AsyncJob;
  /**
   * Get information for a specific order.
   *
   * Requires authorization.
   */
  order: Order;
  /**
   * Get all available orders.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id",
   * "adverts_deliverable_formats_platform_name", "adverts_objective_name",
   * "adverts_original_input_type_name",
   * "approved_deliverables_deliverable_format_filter_name",
   * "approved_deliverables_deliverable_format_platform_name",
   * "approved_deliverables_duration_filter", "client_name",
   * "client_vertical_name", "hubspot_id", "name", "objective_name", "region_name",
   * "serial_id", "state_name", "best_of", "by_effective_state", "client_ancestry",
   * "client_id", "completed_at", "enterprise_ancestry", "full_search_cont",
   * "group_ancestry", "region_ancestry", "state", "submitted_at", "type",
   * "user_id", "eligible_to_create_variations", "client_ancestry_or_client_id",
   * "client_client_partners_id", "client_creative_specialists_id",
   * "client_production_managers_id"]
   *
   * Permitted sort attributes: ["created_at", "active_states", "best_of",
   * "brand_name", "client_name", "completed_at", "created_at", "enterprise_name",
   * "name", "state", "submitted_at", "target_date", "target_date_nulls",
   * "total_deliverables_ordered"]
   */
  orders: Orders;
  /**
   * Get a list of all platforms.
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  platforms: Array<Platform>;
  /**
   * Check if a project brief is valid for submission.
   *
   * Requires authorization.
   */
  projectBriefValidation: ProjectBriefValidation;
  /**
   * Get all available Questions.
   *
   * Requires authorization.
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  questions: Array<Question>;
  /** Get a list of available regions */
  regions: Array<Region>;
  /** Get scopes for the requesting user */
  scopes: Scopes;
  /**
   * Get a list of available studios
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at", "name"]
   */
  studios: Studios;
  /**
   * Get information for a specific User.
   *
   * Requires authorization.
   */
  user?: Maybe<LegacyUser>;
  /**
   * Get all available Users.
   *
   * Requires authorization.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   *
   * Permitted sort attributes: ["created_at", "clients_id"]
   */
  users?: Maybe<Users>;
  /**
   * Get verticals
   *
   * Permitted search attributes: ["id", "name"]
   *
   * Permitted sort attributes: ["created_at"]
   */
  verticals: Verticals;
};


/** The query root of this schema */
export type Query_ConceptArgs = {
  key: Scalars['ID'];
};


/** The query root of this schema */
export type Query_UgcBriefArgs = {
  conceptKey: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAdvertsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryAssetsArgs = {
  assetType?: InputMaybe<AssetType>;
  clientId?: InputMaybe<Scalars['ID']>;
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  integrationKeys?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryAuth0AssumedRoleArgs = {
  auth0IntegrationKey: Scalars['String'];
};


/** The query root of this schema */
export type QueryClientArgs = {
  id?: InputMaybe<Scalars['ID']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryClientsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryCopySuggestionsArgs = {
  audienceIntegrationKey?: InputMaybe<Scalars['ID']>;
  conceptIdea: Scalars['String'];
  description: Scalars['String'];
  responses?: InputMaybe<Array<Scalars['String']>>;
};


/** The query root of this schema */
export type QueryDeliverableFormatFilterNamesArgs = {
  orderId?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryDeliverableFormatsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryDeliverablesArgs = {
  dimensionId?: InputMaybe<Scalars['ID']>;
  fileType?: InputMaybe<DeliverableFileType>;
  limit?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  review?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<DeliverableState>;
};


/** The query root of this schema */
export type QueryInvitationArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryJobArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryOrdersArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  effectiveState?: InputMaybe<OrderState>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  showcase?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<SortInput>>;
  state?: InputMaybe<OrderState>;
};


/** The query root of this schema */
export type QueryPlatformsArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryProjectBriefValidationArgs = {
  integrationKey?: InputMaybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryQuestionsArgs = {
  creativeGroups?: InputMaybe<Array<CreativeGroup>>;
  kept?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['ID']>;
  orderIntegrationKey?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryStudiosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryUserArgs = {
  auth0IntegrationKey?: InputMaybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryUsersArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  integrationKeys?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  region?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortInput>>;
};


/** The query root of this schema */
export type QueryVerticalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** A question */
export type Question = {
  __typename?: 'Question';
  /** Flag indicating that the question can have additional notes. */
  canHaveNotes: Scalars['Boolean'];
  /** Category of the question, determining where it is displayed. */
  category: QuestionCategory;
  /** For internal use. To aid copilot in brief generation. */
  copilotPrompt?: Maybe<Scalars['String']>;
  /** Flag indicating that the question is a copy variation question. */
  copyVariation: Scalars['Boolean'];
  /** Description of the question with additional details. */
  description?: Maybe<Scalars['String']>;
  /** The time this question was archived, or null. */
  discardedAt?: Maybe<Scalars['BigInt']>;
  /** A flag to determine if the question should be displayed regardless of selected formats. */
  fixed: Scalars['Boolean'];
  /** The UUID of the Question. */
  id: Scalars['ID'];
  /** A unique human-readable key for the Question. */
  key: Scalars['String'];
  /** The type of question. */
  kind: QuestionKind;
  /** Text label of the question asked. */
  label?: Maybe<Scalars['String']>;
  /** Determines the default height of `text` and `textarea` questions. */
  minRows?: Maybe<Scalars['Int']>;
  /** Placeholder text to appear before the user interacts. */
  placeholder?: Maybe<Scalars['String']>;
  /** Position determining the order to display question. */
  position?: Maybe<Scalars['Int']>;
  /** QuestionDeliverableFormats belonging to the Question. */
  questionDeliverableFormats: Array<QuestionDeliverableFormat>;
  /** The follow up option. */
  questionOption?: Maybe<QuestionOption>;
  /** Selectable options for multiple choice type questions. */
  questionOptions: Array<QuestionOption>;
  /** Flag determining if the questions must be answered. */
  required: Scalars['Boolean'];
  /** Section the question belongs to within the Category. */
  section?: Maybe<QuestionSection>;
  /** Short text label of the question. */
  shortLabel?: Maybe<Scalars['String']>;
  /** Informative text to be displayed with the question. */
  tooltip?: Maybe<Scalars['String']>;
  /** A flag to determine if the answer should be translated. */
  translatable: Scalars['Boolean'];
};

export enum QuestionCategory {
  Advert = 'advert',
  Campaign = 'campaign'
}

/** A Question Deliverable Format. */
export type QuestionDeliverableFormat = {
  __typename?: 'QuestionDeliverableFormat';
  /** The deliverable format assigned to the Question. */
  deliverableFormat: DeliverableFormat;
  /** ID of the deliverable format assigned to the Question. */
  deliverableFormatId: Scalars['String'];
  /** The length of deliverable. */
  formatLength: FormatLength;
  /** The UUID of the QuestionDeliverableFormat. */
  id: Scalars['ID'];
};

export enum QuestionKind {
  Checkbox = 'checkbox',
  Date = 'date',
  Dropdown = 'dropdown',
  Message = 'message',
  Radio = 'radio',
  Text = 'text',
  Textarea = 'textarea',
  Translatable = 'translatable'
}

/** A question option */
export type QuestionOption = {
  __typename?: 'QuestionOption';
  /**
   * Follow up questions belonging to the question option.
   *
   * Permitted search attributes: ["id", "question_option_id"]
   *
   * Permitted sort attributes: ["created_at", "position"]
   */
  followUpQuestions: Array<Question>;
  /** The UUID of the Question Option. */
  id: Scalars['ID'];
  /** Text label of the selectable option. */
  label: Scalars['String'];
  /** Position value of the Question Option. */
  position: Scalars['Int'];
};


/** A question option */
export type QuestionOptionFollowUpQuestionsArgs = {
  q?: InputMaybe<Scalars['JSON']>;
  sort?: InputMaybe<Array<SortInput>>;
};

/** A Question Section */
export type QuestionSection = {
  __typename?: 'QuestionSection';
  /** The UUID of the Question Section. */
  id: Scalars['ID'];
  /** The Section's key */
  key: QuestionSectionKey;
  /** Position this section (or sub-section) should appear. */
  position: Scalars['Int'];
  /** The Section's primary title. */
  title: Scalars['String'];
};

export enum QuestionSectionKey {
  AdditionalDetails = 'additionalDetails',
  Audio = 'audio',
  ConceptBasics = 'conceptBasics',
  ConceptIdea = 'conceptIdea',
  CopyVariations = 'copyVariations',
  Disclaimer = 'disclaimer',
  Messaging = 'messaging',
  PlatformSpecifics = 'platformSpecifics',
  ProjectBasics = 'projectBasics',
  VoiceOver = 'voiceOver'
}

/** A region */
export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of RejectDeliverables */
export type RejectDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Inputs used for rejecting deliverables. */
  input: DeliverablesRejectInput;
};

/** Autogenerated return type of RejectDeliverables. */
export type RejectDeliverablesPayload = {
  __typename?: 'RejectDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The rejected Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of RemoveDeliverableFormatsFromConcept */
export type RemoveDeliverableFormatsFromConceptInput = {
  /**
   * The advert id
   * @deprecated Use `advertIntegrationKey` instead.
   */
  advertId?: InputMaybe<Scalars['ID']>;
  /** The advert integration key */
  advertIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ids of the advert deliverable formats to remove from the advert */
  formatIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of RemoveDeliverableFormatsFromConcept. */
export type RemoveDeliverableFormatsFromConceptPayload = {
  __typename?: 'RemoveDeliverableFormatsFromConceptPayload';
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RemoveUser */
export type RemoveUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be removed. */
  user: UserRemoveInput;
};

/** Autogenerated return type of RemoveUser. */
export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ReplaceDeliverable */
export type ReplaceDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Deliverable to be replaced. */
  id: Scalars['ID'];
};

/** Autogenerated return type of ReplaceDeliverable. */
export type ReplaceDeliverablePayload = {
  __typename?: 'ReplaceDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be replaced. */
  deliverable?: Maybe<Deliverable>;
};

/** Autogenerated input type of ResendInvitation */
export type ResendInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the User to be reinvited. */
  user: InvitationResendInput;
};

/** Autogenerated return type of ResendInvitation. */
export type ResendInvitationPayload = {
  __typename?: 'ResendInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The invited User. */
  user: LegacyUser;
};

/** Autogenerated input type of RevertBriefToUgcIdeation */
export type RevertBriefToUgcIdeationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be reverted. */
  order: BriefRevertToUgcIdeationInput;
};

/** Autogenerated return type of RevertBriefToUgcIdeation. */
export type RevertBriefToUgcIdeationPayload = {
  __typename?: 'RevertBriefToUgcIdeationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The reverted order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of ReviseOrder */
export type ReviseOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Inputs used for revising a project. */
  input: OrderReviseInput;
};

/** Autogenerated return type of ReviseOrder. */
export type ReviseOrderPayload = {
  __typename?: 'ReviseOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The revised Order. */
  order?: Maybe<Order>;
};

/** Scopes permitted to the requesting user. */
export type Scopes = {
  __typename?: 'Scopes';
  /** Scopes for a given Brand. */
  brand?: Maybe<BrandScopes>;
  /** Scopes for a given Client Account. */
  clientAccount?: Maybe<ClientAccountScopes>;
  /** Scopes for a given Project. */
  project?: Maybe<ProjectScopes>;
};


/** Scopes permitted to the requesting user. */
export type ScopesBrandArgs = {
  key: Scalars['ID'];
};


/** Scopes permitted to the requesting user. */
export type ScopesClientAccountArgs = {
  key: Scalars['ID'];
};


/** Scopes permitted to the requesting user. */
export type ScopesProjectArgs = {
  key: Scalars['ID'];
};

/** Autogenerated input type of SetAssetNotes */
export type SetAssetNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the note. */
  content: Scalars['String'];
  /** The UUID of the Asset. */
  id: Scalars['ID'];
};

/** Autogenerated return type of SetAssetNotes. */
export type SetAssetNotesPayload = {
  __typename?: 'SetAssetNotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  note?: Maybe<AssetNote>;
};

/** Autogenerated input type of SetOrderCopilotStatus */
export type SetOrderCopilotStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Integration key of the order to be updated. */
  integrationKey: Scalars['ID'];
  /** Integration key of the order to be updated. */
  status: CopilotStatus;
};

/** Autogenerated return type of SetOrderCopilotStatus. */
export type SetOrderCopilotStatusPayload = {
  __typename?: 'SetOrderCopilotStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order, or null. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of SetOrderTargetDate */
export type SetOrderTargetDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Target date to be set. */
  input: OrderTargetDateInput;
};

/** Autogenerated return type of SetOrderTargetDate. */
export type SetOrderTargetDatePayload = {
  __typename?: 'SetOrderTargetDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** A container for arguments used when sorting a results */
export type SortInput = {
  /** The field to sort results */
  field: Scalars['String'];
  /** The order to sort results */
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StringEncoding {
  Base64 = 'base64',
  None = 'none'
}

/** A studio. */
export type Studio = {
  __typename?: 'Studio';
  /**
   * Art Directors belonging to the Studio.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  artDirectors: Users;
  /**
   * Designers belonging to the Studio.
   *
   * Permitted search attributes: ["id", "art_director_studio_id",
   * "art_director_studio_id_or_designer_studio_id", "auth0_integration_key",
   * "designer_studio_id", "auth0_user_cont", "auth0_user_or_invitations_cont",
   * "auth0_email_or_invitations_email_eq", "collaborators",
   * "is_admin_or_manager_or_traffic"]
   */
  designers: Users;
  /** The UUID of the Studio. */
  id: Scalars['ID'];
  /** The name of the Studio. */
  name: Scalars['String'];
};


/** A studio. */
export type StudioArtDirectorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};


/** A studio. */
export type StudioDesignersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['JSON']>;
};

/** A paginated array of Studios */
export type Studios = {
  __typename?: 'Studios';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Studios */
  nodes: Array<Studio>;
};

export enum SubmissionState {
  Error = 'error',
  New = 'new',
  Touched = 'touched',
  Valid = 'valid'
}

/** Autogenerated input type of SubmitFormats */
export type SubmitFormatsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the formats to be submitted. */
  input: FormatsSubmitInput;
};

/** Autogenerated return type of SubmitFormats. */
export type SubmitFormatsPayload = {
  __typename?: 'SubmitFormatsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The project formats were submitted for. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of SubmitOrder */
export type SubmitOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be submitted. */
  order: OrderSubmitInput;
};

/** Autogenerated return type of SubmitOrder. */
export type SubmitOrderPayload = {
  __typename?: 'SubmitOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The submitted order. */
  order?: Maybe<Order>;
};

/** A container for arguments used when submitting an order. */
export type SubmittedBriefForCsReviewInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
  /** The date and time the order was submitted. */
  submittedAt: Scalars['ISO8601DateTime'];
  /** The integration key of the user who submitted the order. */
  userIntegrationKey: Scalars['ID'];
};

export enum Ternary {
  No = 'NO',
  Unsure = 'UNSURE',
  Yes = 'YES'
}

/** A region */
export type TrelloBoard = {
  __typename?: 'TrelloBoard';
  boardId: Scalars['String'];
  defaultLabelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  newCardListId: Scalars['String'];
  orderReadyListId?: Maybe<Scalars['String']>;
  productionReviewListId?: Maybe<Scalars['String']>;
  projectCompleteListId: Scalars['String'];
};

/** A UGC brief. */
export type UgcBrief = {
  __typename?: 'UGCBrief';
  /** The audience of the concept. */
  audience?: Maybe<Audience>;
  /** The presigned URL of the brand logo. */
  brandLogoUrl?: Maybe<Scalars['String']>;
  /** The name of the brand. */
  brandName: Scalars['String'];
  /** Concept field to give us a resolver by concept{key} */
  concept: ConceptLite;
  /** The name of the concept. */
  conceptName: Scalars['String'];
  /** The objective of the concept. */
  conceptObjective?: Maybe<Scalars['String']>;
  /** The name of the project. */
  projectName: Scalars['String'];
};

/** A container for arguments used when an order has been submitted. */
export type UgcIdeationSubmissionInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the order. */
  integrationKey: Scalars['ID'];
  /** The date and time the order was submitted. */
  submittedAt: Scalars['ISO8601DateTime'];
  /** The integration key of the user who submitted the order. */
  userIntegrationKey: Scalars['ID'];
};

/** A container for arguments used when unapproving a deliverable */
export type UnapprovedDeliverableInput = {
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /**
   * Event sequence ID of incoming event.
   * @deprecated Related to Kinesis events. Superseded by knative and `event_id`.
   */
  eventSequenceId?: InputMaybe<Scalars['Int']>;
  /** A surrogate UUID used to identify a deliverable when integrating with external apps. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated input type of UpdateAdvert */
export type UpdateAdvertInput = {
  /** Details of the advert to be updated. */
  advert: AdvertUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAdvert. */
export type UpdateAdvertPayload = {
  __typename?: 'UpdateAdvertPayload';
  /** The updated advert. */
  advert?: Maybe<Advert>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAuth0IntegrationKey */
export type UpdateAuth0IntegrationKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Integration Key of the user to be updated. */
  integrationKey: Auth0IntegrationKeyUpdateInput;
};

/** Autogenerated return type of UpdateAuth0IntegrationKey. */
export type UpdateAuth0IntegrationKeyPayload = {
  __typename?: 'UpdateAuth0IntegrationKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The user to be updated. */
  user?: Maybe<LegacyUser>;
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  /** Details of the client to be updated. */
  client: ClientUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateClient. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  /** The updated client. */
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateDeliverable */
export type UpdateDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Fields of the deliverable to be updated. */
  deliverable: DeliverableUpdateInput;
};

/** Autogenerated return type of UpdateDeliverable. */
export type UpdateDeliverablePayload = {
  __typename?: 'UpdateDeliverablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deliverable to be updated. */
  deliverable?: Maybe<Deliverable>;
};

/** Autogenerated input type of UpdateDeliveryTurnaround */
export type UpdateDeliveryTurnaroundInput = {
  /** Reason the delivery turnaround time is being adjusted. */
  adjustedReason: Scalars['String'];
  /** New delivery turnaround time. */
  adjustedTime: Scalars['BigInt'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the target delivery turnaround. */
  key: Scalars['ID'];
};

/** Autogenerated return type of UpdateDeliveryTurnaround. */
export type UpdateDeliveryTurnaroundPayload = {
  __typename?: 'UpdateDeliveryTurnaroundPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Indicates if the mutation was successful. */
  deliveryTurnaround?: Maybe<DeliveryTurnaround>;
};

/** Autogenerated input type of UpdateOrderDeliverables */
export type UpdateOrderDeliverablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be updated. */
  order: OrderDeliverablesUpdateInput;
};

/** Autogenerated return type of UpdateOrderDeliverables. */
export type UpdateOrderDeliverablesPayload = {
  __typename?: 'UpdateOrderDeliverablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the order to be updated. */
  order: OrderUpdateInput;
};

/** Autogenerated return type of UpdateOrder. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateTranslations */
export type UpdateTranslationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTranslations. */
export type UpdateTranslationsPayload = {
  __typename?: 'UpdateTranslationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateTrello */
export type UpdateTrelloInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The UUID of the Order. */
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateTrelloLabels */
export type UpdateTrelloLabelsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID of the incoming event. */
  eventId?: InputMaybe<Scalars['ID']>;
  /** The UUID of the target project. */
  integrationKey: Scalars['ID'];
};

/** Autogenerated return type of UpdateTrelloLabels. */
export type UpdateTrelloLabelsPayload = {
  __typename?: 'UpdateTrelloLabelsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Indicates if the mutation was successful. */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateTrello. */
export type UpdateTrelloPayload = {
  __typename?: 'UpdateTrelloPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Order. */
  order?: Maybe<Order>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Details of the user to be updated. */
  user: UserUpdateInput;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** True if the user was updated, false otherwise. */
  success?: Maybe<Scalars['Boolean']>;
};

/** A container for arguments used when removing a user from an account */
export type UserRemoveInput = {
  /**
   * UUID of the Client to add the new User to.
   * @deprecated Use `clientIntegrationKey` instead
   */
  clientId?: InputMaybe<Scalars['ID']>;
  /** Integration key of the Client to add the new User to. */
  clientIntegrationKey?: InputMaybe<Scalars['ID']>;
  /** UUID of the User to be removed. */
  id: Scalars['ID'];
};

/** A container for arguments used when updating a user. */
export type UserUpdateInput = {
  /** Updated email address of the user, this will need to be reconfirmed if modified. */
  email?: InputMaybe<Scalars['String']>;
  /** Given name of the User. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Family name of the User. */
  lastName?: InputMaybe<Scalars['String']>;
};

/** A paginated array of Users */
export type Users = {
  __typename?: 'Users';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Users */
  nodes: Array<LegacyUser>;
};

/** A vertical */
export type Vertical = {
  __typename?: 'Vertical';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated array of Verticals */
export type Verticals = {
  __typename?: 'Verticals';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Verticals */
  nodes: Array<Vertical>;
};

/** A workflow step */
export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  id: Scalars['ID'];
  maxNumRevisions?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nextStep?: Maybe<WorkflowStep>;
  productionId?: Maybe<Scalars['String']>;
  revisionLimitDays?: Maybe<Scalars['Int']>;
  revisionTurnaroundDays?: Maybe<Scalars['Int']>;
  turnaroundDays?: Maybe<Scalars['Int']>;
};

/** A Working File */
export type WorkingFile = {
  __typename?: 'WorkingFile';
  /** The UUID of the Working File. */
  id: Scalars['ID'];
  /** A surrogate UUID used to identify a Working File in external apps. */
  integrationKey: Scalars['ID'];
  /** The order assigned to the Working File. */
  order?: Maybe<Order>;
  /** The user who uploaded the Working File. */
  user?: Maybe<LegacyUser>;
};

/** A paginated array of Working Files */
export type WorkingFiles = {
  __typename?: 'WorkingFiles';
  /** Metadata for the paginated results */
  metadata: Metadata;
  /** The current page of Working Files */
  nodes: Array<WorkingFile>;
};
